import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../shared/services/user.service';
import { Router } from '@angular/router';
import { AuthService } from '../../shared/services/auth.service';
import { api } from '../../../environments/api';
import { Subscription } from 'rxjs';

import { FindUserResponseInterface } from '../../shared/interfaces/user/find-user/find-user-response.interface';
import { LoginResponseInterface } from '../../shared/interfaces/auth/login-response.interface';
import { UserIdAndSecretInterface } from '../../shared/interfaces/user/user-id-and-secret.interface';
import { LoginDataInterface } from '../../shared/interfaces/auth/login-data.interface';
import { CurrentUserResponseInterface } from '../../shared/interfaces/user/current-user-response.interface';
import { UserInterface } from '../../shared/interfaces/user/user.interface';
import { CookieService } from 'ngx-cookie-service';
import { DOCUMENT } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit {
  form: FormGroup;
  errorMessage: string = api.mainErrorMessage;
  preloaderVisibility: boolean = true;
  checkEmailSubscribe: Subscription;
  loginSubscribe: Subscription;
  getDataSubscribe: Subscription;
  timeout: boolean = true;
  emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$';

  showPassword: boolean = false;

  constructor(
    private router: Router,
    private userService: UserService,
    private authService: AuthService,
    private cookie: CookieService,
    @Inject(DOCUMENT) public document: any,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    if (localStorage.getItem('user-key')) {
      this.router.navigate(['/account']);
      this.userService.setSaveSession(true);
      return;
    } else {
      this.preloaderVisibility = false;
    }

    this.form = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.pattern(this.emailPattern),
      ]),
      password: new FormControl('', [Validators.required]),
      remember: new FormControl(false),
    });
  }

  onSubmit() {
    this.timeout = true;

    const values = this.form.value;
    const saveSession = values.remember;

    const userData: LoginDataInterface = {
      Email: values.email,
      Password: values.password,
    };

    this.preloaderVisibility = true;

    /*
     * Find user by Email
     * */

    this.checkEmailSubscribe = this.userService
      .findUserEmail(values.email)
      .subscribe((response: FindUserResponseInterface) => {
        !api.production ? console.log('Find User Result:', response) : null;

        if (response.FindUserResult.Success) {
          /*
           * Check password
           * */

          this.loginSubscribe = this.authService
            .login(userData)
            .subscribe((response: LoginResponseInterface) => {
              console.log('Login Result:', response);

              if (response.LoginResult.Success) {
                const UserIdAndSecret: UserIdAndSecretInterface = response
                  .LoginResult.UserData as UserIdAndSecretInterface;

                /*
                 * Set cookie time in conformity with type session
                 * */

                let date = new Date();
                const expires = new Date(
                  new Date().setDate(date.getDate() + 7)
                );
                const noExpires = new Date(
                  new Date().getTime() + 300 * 60 * 1000
                );
                localStorage.setItem('user-key', UserIdAndSecret.SecretKey);
                localStorage.setItem('user-id', UserIdAndSecret.UserId);

                if (saveSession) {
                  localStorage.setItem('auth-expires', expires.toString());
                } else {
                  localStorage.setItem('auth-expires', noExpires.toString());
                }

                this.userService.setSaveSession(saveSession);

                /*
                 * Check user type
                 * */

                this.getDataSubscribe = this.userService
                  .getCurrentUserData()
                  .subscribe((response: CurrentUserResponseInterface) => {
                    !api.production
                      ? console.log('CurrentUserResult', response)
                      : null;

                    this.timeout = false;

                    if (response.CurrentUserResult.Success) {
                      const userData: UserInterface = response.CurrentUserResult
                        .UserData as UserInterface;

                      if (userData.TypeId === api.typeUser) {
                        this.userService.setUserData(
                          response.CurrentUserResult
                        );
                        this.router.navigate(['/account']);
                      } else {
                        this.openDialog(
                          'Неверный тип пользователя!',
                          'Вы пытаетесь авторизоваться аккаунтом не в том разделе нашего сервиса. <br> Убедитесь что вы на странице специалистов или клиник (в соответствии с вашим аккаунтом).',
                          true
                        );
                      }
                    } else {
                      this.openDialog(
                        'Неизвестная ошибка!',
                        api.mainErrorMessage,
                        true
                      );
                    }
                  });
              } else {
                this.timeout = false;
                this.openDialog(
                  'Неверный пароль!',
                  'Вы ввели неверный пароль. <br> Убедитесь в правильности ввода пароля, посмотрите отключен ли capslock.',
                  true,
                  true
                );
              }
            });
        } else {
          this.timeout = false;
          this.openDialog(
            'Несуществующий пользователь!',
            'Пользователь с таким E-mail не найден!',
            true
          );
        }
      });

    setTimeout(() => {
      if (this.timeout) {
        this.errorMessage = api.mainErrorMessage;
        this.preloaderVisibility = false;
        this.openDialog('Неизвестная ошибка!', api.mainErrorMessage, true);
        this.checkEmailSubscribe.unsubscribe();
        this.loginSubscribe ? this.loginSubscribe.unsubscribe() : null;
        this.getDataSubscribe ? this.getDataSubscribe.unsubscribe() : null;
      }
    }, 30000);
  }

  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }

  openDialog(
    title: string,
    message: string,
    error: boolean = false,
    restore: boolean = false
  ) {
    const dialogRef = this.dialog.open(SignInDialog, {
      data: {
        title: title,
        message: message,
        restore: restore,
        error: error,
      },
    });

    if (error) {
      dialogRef.afterClosed().subscribe(() => {
        this.preloaderVisibility = false;
        this.authService.logOut(false);
      });
    } else {
      dialogRef.afterClosed().subscribe(() => {
        this.preloaderVisibility = false;
        this.router.navigate(['/account']);
      });
    }
  }
}

@Component({
  selector: 'sign-in-dialog',
  templateUrl: 'sign-in-dialog.html',
})
export class SignInDialog {
  constructor(
    public dialogRef: MatDialogRef<SignInDialog>,
    @Inject(MAT_DIALOG_DATA) public data,
    private router: Router
  ) {}

  goRecover(): void {
    this.dialogRef.close();
    this.router.navigate(['/auth/recover']);
  }
}
