import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../shared/services/user.service';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseService } from '../../shared/services/base.service';
import { PhoneMask } from '../../shared/masks/phone.mask';
import { api } from '../../../environments/api';
import { Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';
import { CustomValidators } from '../../shared/validators/custom.validators';
import {
  doctorSpecialization,
  postGraduateEducationType,
} from '../../shared/catalogs/doctor.catalog';
import { Doctor } from '../../shared/models/doctor/doctor.model';
import { DoctorService } from '../../shared/services/doctor.service';
import { ResponseDoctorEditInterface } from '../../shared/interfaces/doctor/response-doctor-edit.interface';
import { ResponseCurrentUserInterface } from '../../shared/interfaces/response-current-user.interface';
import { DoctorInterface } from '../../shared/interfaces/doctor/doctor.interface';
import { DoctorFullInterface } from '../../shared/interfaces/doctor/doctor-full.interface';
import { ContactSpecializatInterface } from '../../shared/interfaces/doctor/contact-specializat.interface';
import { PublicationsInMediaInterface } from '../../shared/interfaces/doctor/publications-in-media.interface';
import { DevelopmentOfSpecialistInterface } from '../../shared/interfaces/doctor/development-of-specialist.interface';
import { ScientificPublicatInterface } from '../../shared/interfaces/doctor/scientific-publicat.interface';
import { SelectInterface } from '../../shared/interfaces/select.interface';
import { gender } from '../../shared/catalogs/main.catalog';
import { MatDialog } from '@angular/material';
import { DialogInterface } from '../../shared/interfaces/dialog.interface';
import { AccountDialog } from '../account.component';
import {
  dateCompilation,
  dateEncode,
  downscaleImage,
  formatPhone,
  replaceDate,
} from '../../shared/functions/functions';
import { ContactCareersInterface } from '../../shared/interfaces/doctor/contact-careers.interface';
import { isSuccess } from '@angular/http/src/http_utils';
import { PostGraduateEducationsInterface } from '../../shared/interfaces/doctor/post-graduate-educations.interface';
import { DialogService } from '../../shared/services/dialog.service';
import { CatalogNamePipe } from '../../shared/pipes/catalog-name.pipe';

@Component({
  selector: 'app-account-edit',
  templateUrl: './account-edit.component.html',
  styleUrls: ['./account-edit.component.scss'],
})
export class AccountEditComponent implements OnInit {
  userCurrentData: DoctorInterface;
  form: FormGroup;

  errorTitle: string = api.mainErrorTitle;
  errorMessage: string = api.mainErrorMessage;
  preloader: boolean = false;
  timeout: boolean = true;

  phone: string;
  birthday: Date;
  beginningOfExperience: Date;
  genderSelect = gender;

  maxDate: Date = new Date();

  selectSpecialization: SelectInterface[] = doctorSpecialization;
  selectPostGraduateEducationsType: SelectInterface[] = postGraduateEducationType;
  specializations: FormArray;
  publicationsInMedia: FormArray;
  developmentOfSpecialist: FormArray;
  scientificPublicat: FormArray;
  contactCareers: FormArray;
  postGraduateEducations: FormArray;

  postGraduateEducationsFiles: any = [];
  postGraduateEducationsNewFiles: any = [];
  postGraduateEducationsCroppedNewFiles: any = [];

  fullUserData: DoctorFullInterface;

  phoneMask: object = {
    mask: PhoneMask,
  };

  editUserSubscribe: Subscription;
  getCurrentUserDataSubscribe: Subscription;

  filesPetCurrent: TemplateRef<any> | null = null;

  constructor(
    public userService: UserService,
    public doctorService: DoctorService,
    public router: Router,
    private dialog: MatDialog,
    private datePipe: DatePipe,
    private customValidators: CustomValidators,
    private baseService: BaseService,
    private dialogService: DialogService,
    private catalogNamePipe: CatalogNamePipe
  ) {}

  ngOnInit() {
    let data = this.userService.getUserData();

    this.userCurrentData = data.UserData;
    this.fullUserData = data as DoctorFullInterface;

    this.formInit();
  }

  formInit() {
    let phone = this.userCurrentData.Phone;

    phone ? (this.phone = phone.slice(1)) : (this.phone = null);

    this.checkBirthday();
    this.checkExperience();

    this.form = new FormGroup({
      email: new FormControl(this.userCurrentData.Email, [
        Validators.required,
        this.customValidators.email,
      ]),
      phone: new FormControl(this.phone),
      firstName: new FormControl(this.userCurrentData.Firstname, [
        Validators.required,
      ]),
      surName: new FormControl(this.userCurrentData.Surname, [
        Validators.required,
      ]),
      middleName: new FormControl(this.userCurrentData.Middlename),
      gender: new FormControl(this.userCurrentData.Gender, [
        Validators.required,
      ]),
      birthday: new FormControl(this.birthday),
      city: new FormControl(this.userCurrentData.City, [Validators.required]),
      aboutUser: new FormControl(this.userCurrentData.AboutSpecialist),
      education: new FormControl(this.userCurrentData.Education),
      experience: new FormControl(this.userCurrentData.Experience),
      departureHouse: new FormControl(this.userCurrentData.DepartureHouse),
      personalArea: new FormControl(this.userCurrentData.PersonalArea),
      beginningOfExperience: new FormControl(this.beginningOfExperience),
      membershipName: new FormControl(this.userCurrentData.MemberOfCommunities),
      membershipNumber: new FormControl(this.userCurrentData.MembershipNumber),
      dateToEnterMemeberToCommutinites: new FormControl(
        this.userCurrentData.DateToEnterMemeberToCommutinites
      ),
      specialization: new FormArray([]),
      publicationsInMedia: new FormArray([]),
      developmentOfSpecialist: new FormArray([]),
      scientificPublicat: new FormArray([]),
      contactCareers: new FormArray([]),
      postGraduateEducations: new FormArray([]),
    });

    this.postGraduateEducations = this.form.get(
      'postGraduateEducations'
    ) as FormArray;

    const postGraduateEducations = this.fullUserData.PostGraduateEducations;

    if (postGraduateEducations.length) {
      for (let key in postGraduateEducations) {
        this.postGraduateEducations.push(
          new FormGroup({
            id: new FormControl(postGraduateEducations[key].Id),
            name: new FormControl(postGraduateEducations[key].Name),
            typeId: new FormControl(postGraduateEducations[key].TypeId),
            date: new FormControl(dateEncode(postGraduateEducations[key].Date)),
            place: new FormControl(postGraduateEducations[key].Place),
          })
        );

        const files = postGraduateEducations[key].Files;
        const filesLength = files.length;

        this.postGraduateEducationsFiles[key] = [];

        if (filesLength) {
          for (let i = 0; i < filesLength; i++) {
            const id = files[i].Id;
            const name = files[i].Name;
            const content = files[i].Content;

            if (files[i].Name.length) {
              let filename: any = name.split('.');

              filename = filename[filename.length - 1];

              this.postGraduateEducationsFiles[key].push({
                Id: id,
                Name: name,
                Content: 'data:image/' + filename + ';base64,' + content,
              });
            }
          }
        }
      }
    }

    this.contactCareers = this.form.get('contactCareers') as FormArray;

    const contactCareers = this.fullUserData.ContactCareers;

    if (contactCareers.length) {
      for (let key in contactCareers) {
        this.contactCareers.push(
          new FormGroup({
            id: new FormControl(contactCareers[key].Id),
            clinicId: new FormControl(contactCareers[key].ClinicId),
            // 'departmentId': new FormControl(contactCareers[key].DepartmentId),
            // 'jobId': new FormControl(contactCareers[key].JobId),
            jobTitle: new FormControl(contactCareers[key].JobTitle),
            primary: new FormControl(contactCareers[key].Primary),
            current: new FormControl(contactCareers[key].Current),
            startDate: new FormControl(contactCareers[key].StartDate),
            dueDate: new FormControl(contactCareers[key].DueDate),
            // 'jobChangeReasonId': new FormControl(contactCareers[key].JobChangeReasonId),
            description: new FormControl(contactCareers[key].Description),
            // 'decisionRoleId': new FormControl(contactCareers[key].DecisionRoleId),
            specializationId: new FormControl(
              {
                value: contactCareers[key].SpecializationId,
                name: this.catalogNamePipe.transform(
                  contactCareers[key].SpecializationId,
                  this.selectSpecialization
                ),
              },
              [Validators.required, this.customValidators.autofillForceRequire]
            ),
          })
        );
      }
    }

    this.specializations = this.form.get('specialization') as FormArray;

    const specializations = this.fullUserData.ContactSpecializat;

    if (specializations.length) {
      for (let key in specializations) {
        this.specializations.push(
          new FormGroup({
            id: new FormControl(specializations[key].Id),
            specialization: new FormControl(
              {
                value: specializations[key].Specialization,
                name: this.catalogNamePipe.transform(
                  specializations[key].Specialization,
                  this.selectSpecialization
                ),
              },
              [Validators.required, this.customValidators.autofillForceRequire]
            ),
            typeOfAnimals: new FormControl(specializations[key].TypeOfAnimals),
          })
        );
      }
    }

    this.publicationsInMedia = this.form.get(
      'publicationsInMedia'
    ) as FormArray;

    const publicationsInMedia = this.fullUserData.PublicationsInMedia;

    if (publicationsInMedia.length) {
      for (let key in publicationsInMedia) {
        this.publicationsInMedia.push(
          new FormGroup({
            id: new FormControl(publicationsInMedia[key].Id),
            title: new FormControl(publicationsInMedia[key].PublicationTitle, [
              Validators.required,
            ]),
            link: new FormControl(publicationsInMedia[key].LinkToArticle, [
              Validators.required,
            ]),
          })
        );
      }
    }

    this.developmentOfSpecialist = this.form.get(
      'developmentOfSpecialist'
    ) as FormArray;

    const developmentOfSpecialist = this.fullUserData.DevelopmentOfSpecialist;

    if (developmentOfSpecialist.length) {
      let dateDevelopmentOfSpecialist = [];

      for (let key in developmentOfSpecialist) {
        if (developmentOfSpecialist[key].Year) {
          dateDevelopmentOfSpecialist[key] = new Date(
            replaceDate(developmentOfSpecialist[key].Year)
          );
        }

        this.developmentOfSpecialist.push(
          new FormGroup({
            id: new FormControl(developmentOfSpecialist[key].Id),
            name: new FormControl(
              developmentOfSpecialist[key].NameDevelopment,
              [Validators.required]
            ),
            year: new FormControl(dateDevelopmentOfSpecialist[key], [
              Validators.required,
            ]),
          })
        );
      }
    }

    this.scientificPublicat = this.form.get('scientificPublicat') as FormArray;

    const scientificPublicat = this.fullUserData.ScientificPublicat;

    if (scientificPublicat.length) {
      let dateScientificPublicat = [];

      for (let key in scientificPublicat) {
        if (scientificPublicat[key].Year) {
          dateScientificPublicat[key] = new Date(
            replaceDate(scientificPublicat[key].Year)
          );
        }

        this.scientificPublicat.push(
          new FormGroup({
            id: new FormControl(scientificPublicat[key].Id, [
              Validators.required,
            ]),
            theme: new FormControl(scientificPublicat[key].ThemeOfPublication, [
              Validators.required,
            ]),
            edition: new FormControl(scientificPublicat[key].Edition, [
              Validators.required,
            ]),
            year: new FormControl(dateScientificPublicat[key], [
              Validators.required,
            ]),
            link: new FormControl(scientificPublicat[key].LinkToPublication, [
              Validators.required,
            ]),
          })
        );
      }
    }
  }

  addSpecialization(): void {
    this.specializations.push(
      new FormGroup({
        id: new FormControl(),
        specialization: new FormControl(null, [
          Validators.required,
          this.customValidators.autofillForceRequire,
        ]),
      })
    );
  }

  addPostGraduateEducations(): void {
    this.postGraduateEducations.push(
      new FormGroup({
        id: new FormControl(),
        name: new FormControl(),
        typeId: new FormControl(),
        date: new FormControl(),
        place: new FormControl(),
      })
    );
  }

  addCareers(): void {
    this.contactCareers.push(
      new FormGroup({
        id: new FormControl(null),
        clinicId: new FormControl(),
        primary: new FormControl(null),
        current: new FormControl(null),
        startDate: new FormControl(null, [Validators.required]),
        dueDate: new FormControl(null),
        description: new FormControl(null, [Validators.required]),
        specializationId: new FormControl(null, [
          Validators.required,
          this.customValidators.autofillForceRequire,
        ]),
        jobTitle: new FormControl(null, [Validators.required]),
        // 'departmentId': new FormControl(),
        // 'jobId': new FormControl(),
        // 'jobChangeReasonId': new FormControl(),
        // 'decisionRoleId': new FormControl()
      })
    );
  }

  addPublicationsInMedia(): void {
    this.publicationsInMedia.push(
      new FormGroup({
        id: new FormControl(),
        title: new FormControl(null, [Validators.required]),
        link: new FormControl(null, [Validators.required]),
      })
    );
  }

  addDevelopmentOfSpecialist(): void {
    this.developmentOfSpecialist.push(
      new FormGroup({
        id: new FormControl(),
        name: new FormControl(null, [Validators.required]),
        year: new FormControl(null, [Validators.required]),
      })
    );
  }

  addScientificPublicat(): void {
    this.scientificPublicat.push(
      new FormGroup({
        id: new FormControl(),
        theme: new FormControl(null, [Validators.required]),
        edition: new FormControl(null, [Validators.required]),
        year: new FormControl(null, [Validators.required]),
        link: new FormControl(null, [Validators.required]),
      })
    );
  }

  removeCareers(i): void {
    this.contactCareers.removeAt(i);
  }

  removeSpecialization(i): void {
    this.specializations.removeAt(i);
  }

  removePublicationsInMedia(i): void {
    this.publicationsInMedia.removeAt(i);
  }

  removeDevelopmentOfSpecialist(i): void {
    this.developmentOfSpecialist.removeAt(i);
  }

  removeScientificPublicat(i): void {
    this.scientificPublicat.removeAt(i);
  }

  checkBirthday() {
    if (this.userCurrentData.BirthDate != '0001-01-01') {
      this.birthday = new Date(this.userCurrentData.BirthDate);
    }
  }

  checkExperience() {
    if (this.userCurrentData.BeginningOfExperience != '0001-01-01') {
      this.beginningOfExperience = new Date(
        this.userCurrentData.BeginningOfExperience
      );
    }
  }

  /*
   * Files
   * */

  clearFile(i, j) {
    this.postGraduateEducationsNewFiles[i].splice(j, 1);
  }

  preSubmit() {
    if (this.postGraduateEducationsNewFiles.length) {
      let convertFiles: any = [];

      for (let i = 0; i < this.postGraduateEducationsNewFiles.length; i++) {
        if (this.postGraduateEducationsNewFiles[i].length) {
          convertFiles[i] = [];

          for (
            let j = 0;
            j < this.postGraduateEducationsNewFiles[i].length;
            j++
          ) {
            let reader = new FileReader();

            const name = this.postGraduateEducationsNewFiles[i][j].name;
            const file = this.postGraduateEducationsNewFiles[i][j];

            reader.readAsDataURL(file);

            reader.onload = () => {
              const content = downscaleImage(reader.result, 1000).split(',')[1];

              convertFiles[i].push({
                Name: name,
                Content: content,
              });
            };
          }
        }
      }

      const interval = setInterval(() => {
        if (
          convertFiles.flat().length ==
          this.postGraduateEducationsNewFiles.flat().length
        ) {
          clearInterval(interval);

          !api.production ? console.log('isSuccess') : null;

          this.postGraduateEducationsCroppedNewFiles = convertFiles;
          this.onSubmit();
        }
      }, 100);
    } else {
      this.onSubmit();
    }
  }

  onSubmit() {
    this.preloader = true;

    const value = this.form.value;

    if (value.birthday != '01.01.0001' && value.birthday != this.birthday) {
      value.birthday = this.datePipe.transform(value.birthday, 'MM.dd.yyyy');
    }

    value.birthday = this.datePipe.transform(value.birthday, 'MM.dd.yyyy');

    if (
      value.beginningOfExperience != '01.01.0001' &&
      value.beginningOfExperience != this.beginningOfExperience
    ) {
      value.beginningOfExperience = this.datePipe.transform(
        value.beginningOfExperience,
        'MM.dd.yyyy'
      );
    }

    value.beginningOfExperience = this.datePipe.transform(
      value.beginningOfExperience,
      'MM.dd.yyyy'
    );
    let phone = null;
    if (value.phone) {
      phone = formatPhone(value.phone);
    }

    /*
     * Add Specialisation
     * */

    let specializations: ContactSpecializatInterface[] = [];

    for (let key in value.specialization) {
      if (value.specialization[key]) {
        let data: ContactSpecializatInterface = {
          Specialization: value.specialization[key].specialization.value,
        };
        value.specialization[key].id
          ? (data.Id = value.specialization[key].id)
          : null;
        specializations.push(this.baseService.clearEmpty(data));
      }
    }

    /*
     * Add Publications in Media
     * */

    let publicationsInMedia: PublicationsInMediaInterface[] = [];

    for (let key in value.publicationsInMedia) {
      if (value.publicationsInMedia[key]) {
        let data: PublicationsInMediaInterface = {
          PublicationTitle: value.publicationsInMedia[key].title,
          LinkToArticle: value.publicationsInMedia[key].link,
        };
        value.publicationsInMedia[key].id
          ? (data.Id = value.publicationsInMedia[key].id)
          : null;
        publicationsInMedia.push(this.baseService.clearEmpty(data));
      }
    }

    /*
     * Add Development Of Specialist
     * */

    let developmentOfSpecialist: DevelopmentOfSpecialistInterface[] = [];

    for (let key in value.developmentOfSpecialist) {
      if (value.developmentOfSpecialist[key]) {
        let year;

        if (this.fullUserData.DevelopmentOfSpecialist[key]) {
          if (
            value.developmentOfSpecialist[key].year !=
            this.fullUserData.DevelopmentOfSpecialist[key].Year
          ) {
            year = this.datePipe.transform(
              value.developmentOfSpecialist[key].year,
              'MM.dd.yyyy'
            );
          } else {
            year = value.developmentOfSpecialist[key].year;
          }
        } else {
          year = this.datePipe.transform(
            value.developmentOfSpecialist[key].year,
            'MM.dd.yyyy'
          );
        }

        let data: DevelopmentOfSpecialistInterface = {
          NameDevelopment: value.developmentOfSpecialist[key].name,
          Year: year,
        };
        value.developmentOfSpecialist[key].id
          ? (data.Id = value.developmentOfSpecialist[key].id)
          : null;
        developmentOfSpecialist.push(this.baseService.clearEmpty(data));
      }
    }

    /*
     * Add Scientific Publicat
     * */

    let scientificPublicat: ScientificPublicatInterface[] = [];

    for (let key in value.scientificPublicat) {
      if (value.scientificPublicat[key]) {
        let year;

        if (this.fullUserData.ScientificPublicat[key]) {
          if (
            value.scientificPublicat[key].year !=
            this.fullUserData.ScientificPublicat[key].Year
          ) {
            year = this.datePipe.transform(
              value.scientificPublicat[key].year,
              'MM.dd.yyyy'
            );
          } else {
            year = value.scientificPublicat[key].year;
          }
        } else {
          year = this.datePipe.transform(
            value.scientificPublicat[key].year,
            'MM.dd.yyyy'
          );
        }

        let data: ScientificPublicatInterface = {
          ThemeOfPublication: value.scientificPublicat[key].theme,
          Edition: value.scientificPublicat[key].edition,
          Year: year,
          LinkToPublication: value.scientificPublicat[key].link,
        };
        value.scientificPublicat[key].id
          ? (data.Id = value.scientificPublicat[key].id)
          : null;
        scientificPublicat.push(this.baseService.clearEmpty(data));
      }
    }

    /*
     * Add Contact Careers
     * */

    let contactCareers: ContactCareersInterface[] = [];

    for (let key in value.contactCareers) {
      if (value.contactCareers[key]) {
        let data: ContactCareersInterface = {
          ClinicId: '40e04bab-4eca-4ed1-b0df-b492e2cd596c',
          JobTitle: value.contactCareers[key].jobTitle,
          Primary: value.contactCareers[key].primary ? 1 : 0,
          Current: value.contactCareers[key].current ? 1 : 0,
          StartDate: dateCompilation(value.contactCareers[key].startDate),
          DueDate: dateCompilation(value.contactCareers[key].dueDate),
          Description: value.contactCareers[key].description,
          SpecializationId: value.contactCareers[key].specializationId.value,
        };

        value.contactCareers[key].id
          ? (data.Id = value.contactCareers[key].id)
          : null;
        contactCareers.push(this.baseService.clearEmpty(data));
      }
    }

    /*
     * Add Post Graduate Educations
     * */

    let postGraduateEducations: PostGraduateEducationsInterface[] = [];

    let countPGE = 0;

    for (let key in value.postGraduateEducations) {
      let files = this.postGraduateEducationsCroppedNewFiles[countPGE];

      if (value.postGraduateEducations[key]) {
        let data: PostGraduateEducationsInterface = {
          Name: value.postGraduateEducations[key].name,
          TypeId: value.postGraduateEducations[key].typeId,
          Date: dateCompilation(value.postGraduateEducations[key].date),
          Place: value.postGraduateEducations[key].place,
          Files: this.postGraduateEducationsCroppedNewFiles[countPGE],
        };
        value.postGraduateEducations[key].id
          ? (data.Id = value.postGraduateEducations[key].id)
          : null;
        files ? (data.Files = files) : null;
        postGraduateEducations.push(this.baseService.clearEmpty(data));
      }
    }

    /*
     * Compile request from form values
     * */

    let userCurrentEditData: DoctorFullInterface = {
      SourceID: api.sourceID,
      SourceSecretKey: api.sourceSecretKey,
      UserData: {
        Firstname: value.firstName,
        Surname: value.surName,
        Middlename: value.middleName,
        Gender: value.gender,
        City: value.city,
        BirthDate: value.birthday,
        AboutSpecialist: value.aboutUser,
        Email: value.email,
        Phone: phone,
        Education: value.education,
        Experience: value.experience,
        DepartureHouse: value.departureHouse,
        PersonalArea: value.personalArea,
        BeginningOfExperience: value.beginningOfExperience,
        MemberOfCommunities: value.membershipName,
        MembershipNumber: value.membershipNumber,
        DateToEnterMemeberToCommutinites: value.dateToEnterMemeberToCommutinites
          ? dateCompilation(value.dateToEnterMemeberToCommutinites)
          : null,
      },
    };

    specializations.length
      ? (userCurrentEditData.ContactSpecializat = specializations)
      : null;
    publicationsInMedia.length
      ? (userCurrentEditData.PublicationsInMedia = publicationsInMedia)
      : null;
    developmentOfSpecialist.length
      ? (userCurrentEditData.DevelopmentOfSpecialist = developmentOfSpecialist)
      : null;
    scientificPublicat.length
      ? (userCurrentEditData.ScientificPublicat = scientificPublicat)
      : null;
    contactCareers.length
      ? (userCurrentEditData.ContactCareers = contactCareers)
      : null;
    postGraduateEducations.length
      ? (userCurrentEditData.PostGraduateEducations = postGraduateEducations)
      : null;

    !api.production
      ? console.log('userCurrentEditData', userCurrentEditData)
      : null;

    this.editUser(userCurrentEditData);
  }

  editUser(userCurrentEditData) {
    const timeout = setTimeout(() => {
      this.dialogService.showStandardError();
      this.editUserSubscribe.unsubscribe();
    }, api.requestTimeout);

    this.editUserSubscribe = this.doctorService
      .editDoctor(userCurrentEditData)
      .subscribe(
        (response: ResponseDoctorEditInterface) => {
          !api.production ? console.log('EditUserResult', response) : null;
          clearTimeout(timeout);

          if (response.EditUserResult.Success) {
            this.updateData();
          } else {
            this.preloader = false;
            const warnings = response.EditUserResult.Warnings;
            let errors: string[] = [];
            let errorMessage = this.errorMessage;
            let errorTitle = this.errorTitle;

            for (let key in warnings) {
              if (warnings[key].FieldName == 'Request.UserData.Phone') {
                errors.push(
                  'Пользователь с таким телефоном уже существует. Пожалуйста укажите другой телефон!'
                );
              }
              if (warnings[key].FieldName == 'Request.UserData.Email') {
                errors.push(
                  'Пользователь с таким E-mail уже существует. Пожалуйста укажите другой E-mail!'
                );
              }
            }

            if (errors.length) {
              errorMessage = '';
              errorTitle = 'Ошибка!';

              for (let key in errors) {
                errorMessage += errors[key];
                if (parseInt(key) != errors.length - 1) {
                  errorMessage += '<br><br>';
                }
              }
            }

            this.dialogService.openStatusDialog(errorTitle, errorMessage, true);
          }
        },
        () => {
          clearTimeout(timeout);
          this.preloader = false;
          this.dialogService.showStandardError();
        }
      );
  }

  updateData() {
    const timeout = setTimeout(() => {
      this.dialogService.showStandardError();
      this.getCurrentUserDataSubscribe.unsubscribe();
    }, api.requestTimeout);

    this.getCurrentUserDataSubscribe = this.userService
      .getCurrentUserData()
      .subscribe(
        (userData: ResponseCurrentUserInterface) => {
          !api.production ? console.log('CurrentUserResult', userData) : null;
          clearTimeout(timeout);

          if (userData.CurrentUserResult.Success) {
            this.userService.setUserData(userData.CurrentUserResult);

            this.userCurrentData = userData.CurrentUserResult
              .UserData as Doctor;
            this.checkBirthday();
            this.checkExperience();
            this.preloader = false;

            this.dialogService
              .openStatusDialog(
                'Информация успешно изменена!',
                'Вы успешно изменили информацию о себе.',
                false,
                'back-profile'
              )
              .subscribe((result) => {
                this.preloader = false;
                result == 'back-profile'
                  ? this.router.navigate(['/account'])
                  : null;
              });
          } else {
            this.dialogService.showStandardError();
          }
        },
        () => {
          clearTimeout(timeout);
          this.dialogService.showStandardError();
        }
      );
  }

  showForm() {
    !api.production ? console.log('form', this.form) : null;
  }
}
