import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  AppointmentDraft,
  Diagnosis,
  DiagnosisModel,
} from '../../../shared/interfaces/appointments/appointments';
import { diagnoseList } from '../../../shared/catalogs/doctor.catalog';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { SelectInterface } from '../../../shared/interfaces/select.interface';
import { diagnosisType } from '../../../shared/catalogs/appointment.catalog';
import { AppointmentService } from '../../../shared/services/appointment.service';

@Component({
  selector: 'app-diagnosis-form',
  templateUrl: './diagnosis-form.component.html',
  styleUrls: ['./diagnosis-form.component.scss'],
})
export class DiagnosisFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() appointmentDraft: AppointmentDraft;
  @Input() appointmentAfterSaveDraft: AppointmentDraft;
  @Input() updateTypeCreate: boolean;
  @Input()
  set submit(dateSubmit: Date) {
    if (dateSubmit) {
      this.onPrepare();
    }
  }
  @Output() submitted = new EventEmitter();
  form: FormGroup;
  diagnoseCatalog = diagnoseList;
  selectDiagnosisType: SelectInterface[] = diagnosisType;
  init = true;

  constructor(
    private fb: FormBuilder,
    private appointmentService: AppointmentService
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      diagnosis: this.fb.array([]),
    });

    if (this.appointmentDraft !== undefined) {
      if (!this.updateTypeCreate) {
        this.updateFormAfterGet();
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.appointmentDraft &&
      this.appointmentDraft !== undefined &&
      this.form
    ) {
      if (!this.updateTypeCreate) {
        this.updateFormAfterGet();
      }
    }

    if (
      changes.appointmentAfterSaveDraft &&
      this.appointmentAfterSaveDraft !== undefined &&
      this.form
    ) {
      this.updateIds();
    }
  }

  updateFormAfterGet() {
    this.init = true;
    const controls = this.form.get('diagnosis') as FormArray;
    while (controls.length) {
      controls.removeAt(0);
    }
    this.form.reset();
    this.appointmentDraft.Diagnosis.map((x) => {
      const diagnosis = new Diagnosis();
      diagnosis.Id = x.Id;
      diagnosis.Name = x.Name;
      diagnosis.DiagnosisTypeId = x.DiagnosisTypeId;
      diagnosis.Date = x.Date;

      this.addDiagnosis(diagnosis);
    });
  }

  addDiagnosis(model?: Diagnosis) {
    const controls = this.form.get('diagnosis') as FormArray;
    if (model) {
      console.log('model', model.DiagnosisTypeId);
      controls.push(
        this.fb.group({
          id: model.Id,
          codeName: { value: model.Name, name: model.Name },
          diagnosisType: model.DiagnosisTypeId,
          date: model.Date,
        })
      );

      console.log(controls);
    } else {
      controls.push(
        this.fb.group({
          id: [],
          codeName: [],
          diagnosisType: [],
          date: [],
        })
      );
    }
  }

  updateIds() {
    const controls = this.form.get('diagnosis') as FormArray;

    console.warn(this.appointmentAfterSaveDraft.Diagnosis, controls);

    this.appointmentAfterSaveDraft.Diagnosis.map((x, i) => {
      controls.at(i).patchValue({ id: x.Id });
    });
  }

  getControls(form, group: string) {
    return form.get(group).controls;
  }

  removeFormGroup(form, group: string, i) {
    const controls = form.get(group) as FormArray;
    controls.removeAt(i);
  }

  onSubmit() {
    const prepare = this.prepareForm();
    this.submitted.emit(prepare);
  }

  onPrepare() {
    this.appointmentService.prepareDiagnosisForm = this.prepareForm();
  }

  prepareForm() {
    const controls = this.form.controls;
    const _model: DiagnosisModel[] = [];

    controls.diagnosis.value.map((d) => {
      let codeName = d.codeName;
      if (typeof codeName !== 'string' && codeName) {
        codeName = d.codeName.value;
      }
      let date = d.date;
      if (typeof date !== 'string' && date) {
        date = date.format();
      }
      const diagnose = new DiagnosisModel();
      diagnose.init();
      if (d.id) {
        diagnose.Id = d.id;
      }
      diagnose.Name.Value = codeName;
      diagnose.DiagnosisType.Value = d.diagnosisType;
      diagnose.Date.Value = date;

      _model.push(diagnose);
    });

    return _model;
  }

  ngOnDestroy() {}
}
