export const api = {
  production: false,
  sourceID: '872b875d-efb6-4a23-a24a-17781a8f314c',
  sourceSecretKey: 'r4L6vo1UWBC8CmvruXKv',
  dataSourceID: '3fd3560c-a814-4f91-b273-f80497c07f2b',
  dataSourceSecretKey: 'b9AyP0WQAdW72pV3LvkM',
  simpleRegistrationSourceId: '69761499-6bca-4c16-b0e6-f965dcb169b8',
  simpleRegistrationSourceSecretKey: '10PoFtY11VNATXdkz2ZY',
  typeUser: '4c1367d8-c39d-4fc0-803b-fb9bd706d230',
  baseDomain: 'https://marko.dsml.ru',
  baseUrl: '/0/ServiceModel/SiteIntegrationService.svc/',
  baseUrlService: '/0/ServiceModel/UtilityService.svc/',
  mainErrorTitle: 'Неизвестная ошибка!',
  mainErrorMessage:
    'Возникла неизвестная ошибка. Попробуйте позже или обратитесь в службу поддержки.',
  mainErrorDataMessage:
    'Невозможно загрузить данные. Попробуйте позже или обратитесь в службу поддержки.',
  dadataApi: '06d0ef8959e777e1dc0a6f42ae76bce73f3cd5e8',
  dadataFiases: {
    moscow: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
  },
  typesOfUsers: {
    owner: '393101e7-694d-4e0c-8719-0000d4f2c51d',
    doctor: '4c1367d8-c39d-4fc0-803b-fb9bd706d230',
    clinic: '534edd7d-6d00-4950-a392-ba0da3e35c82',
  },
  switchRelationTypeList: {
    owner: 'cfaa76c3-4d1b-4da1-9b45-d83a66b04d85',
    doctor: '0da918ae-a888-4764-b47f-f8a203d77fdc',
    decline: 'a25ca898-792c-44f2-b348-b028fc0ad121',
    cancel: '589cd02a-b1b4-4941-934e-91661c3aa834',
    hide: 'd08b1ad4-5756-4ec4-8d8f-5e705c1e31b0',
    wait: 'c8ce90e1-d74b-458b-94d6-71b80849956f',
    remove: 'f0aa724d-6b4d-477d-9f88-dc1a7fd8e153',
  },
  switchAppointmentList: {
    notStarted: '384d4b84-58e6-df11-971b-001d60e938c6',
    progress: '394d4b84-58e6-df11-971b-001d60e938c6',
    completed: '4bdbb88f-58e6-df11-971b-001d60e938c6',
    canceled: '201cfba8-58e6-df11-971b-001d60e938c6',
    new: '6c062529-990b-4c0d-8f42-99cf0131d1ea',
    confirm: '09b3369f-8b51-48d4-ba6c-82b1d2a4de90',
    notConfirm: '01a7c232-a23c-459a-b2a9-190bac41b973',
    maybeeCanceled: '8e53c054-72fe-4e4a-b3fe-f57571e91990',
  },
  requestTimeout: 60000,
  dataCodes: {
    getSpecialistsInRejectedOrExpectedStatus:
      'GetSpecialistsInRejectedOrExpectedStatus',
    getAllSpecialists: 'GetAllSpecialists',
    getPetsByName: 'GetPetsByName',
  },
  paths: {
    data: 'Data',
    auth: {
      registration: 'Registration',
      simpleRegistration: 'SimpleRegistration',
      login: 'Login',
      simpleLogin: 'SimpleLogin',
      logout: 'Logout',
      isValid: 'IsValid',
      restore: 'RestorePassword',
    },
    pets: {
      findPets: 'FindPets',
      registrationPet: 'RegistrationPet',
      editPet: 'EditPet',
      deletePet: 'DeletePet',
    },
    user: {
      switchRelationType: 'SwitchRelationType',
      findUser: 'FindUser',
      editUser: 'EditUser',
      addUserEvent: 'AddUserEvent',
      updateSiteUserHash: 'UpdateSiteUserHash',
      confirmCommunicationByCode: 'ConfirmCommunicationByCode',
      sendConfirmationCode: 'SendConfirmationCode',
      needCommunicationConfirmation: 'NeedCommunicationConfirmation',
      currentUser: 'CurrentUser',
    },
    doctor: {
      getAppointment: 'GetAppointment',
    },
    clinic: {
      findClinic: 'FindClinic',
      currentClinic: 'CurrentClinic',
      editAccount: 'EditAccount',
      addSpecialist: 'AddSpecialist',
      getSpecialist: 'GetSpecialist',
      simpleClinicRegistration: 'SimpleClinicRegistration',
      deleteAccountDetail: 'DeleteAccountDetail',
    },
    schedule: {
      addClinicSpecialistSchedule: 'CreateSpecialistSchedule',
      currentSpecialistSchedule: 'CurrentSpecialistSchedule',
      publishSpecialistSchedule: 'PublishSpecialistSchedule',
      getSpecialistScheulderWithAppointment:
        'GetSpecialistScheulderWithAppointment',
      removeSpecialistSchedule: 'RemoveSpecialistSchedule',
      createAppointment: 'CreateAppointment',
      editAppointment: 'EditAppointment',
      switchAppointment: 'SwitchAppointment',
      createScheduleBlocking: 'CreateScheduleBlocking',
      removeScheduleBlocking: 'RemoveScheduleBlocking',
    },
  },
};
