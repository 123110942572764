import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  Anamnesis,
  AppointmentAnamnesisType,
  AppointmentDraft,
  GetHistoryModel,
  HealthFeatures,
  HistoryCard,
} from '../../../shared/interfaces/appointments/appointments';
import { AppointmentService } from '../../../shared/services/appointment.service';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { petLengthCoat } from '../../../shared/catalogs/pets.catalog';
import { map } from 'rxjs/operators';
import { SelectInterface } from '../../../shared/interfaces/select.interface';
import {
  frequency,
  vaccinationCategory,
  vaccinationStatus,
} from '../../../shared/catalogs/appointment.catalog';
import { FormControl } from '@angular/forms';
import { copyArrayOfObject } from '../../../shared/functions/functions';
import * as moment from 'moment';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
})
export class HistoryComponent implements OnInit, OnDestroy {
  @Input() appointmentDraft: AppointmentDraft;
  historyToggle = {};
  history: HistoryCard[];
  historyFiltered: HistoryCard[];
  catalogLengthCoat = petLengthCoat;
  anamnesisType = AppointmentAnamnesisType;
  isLoading = true;
  clinicServices: SelectInterface[] = [];
  clinicMedicines: SelectInterface[] = [];
  frequencySelect: SelectInterface[] = frequency;
  selectCategory: SelectInterface[] = vaccinationCategory;
  selectStatus: SelectInterface[] = vaccinationStatus;
  displayedColumns: string[] = ['date', 'status', 'count', 'name', 'type'];
  searchControl: FormControl;

  constructor(private appointmentService: AppointmentService) {
    moment().locale('ru');
  }

  ngOnInit() {
    this.getHistory();
    this.initSearch();

    this.appointmentService
      .getClinicServices(this.appointmentDraft.AccountId)
      .pipe<SelectInterface[]>(untilDestroyed(this))
      .subscribe((res) => {
        this.clinicServices = res;
      });

    this.appointmentService
      .getClinicMedicines(this.appointmentDraft.AccountId)
      .pipe<SelectInterface[]>(untilDestroyed(this))
      .subscribe((res) => {
        this.clinicMedicines = res;
      });
  }

  getHistory() {
    const request = new GetHistoryModel();
    request.petId = this.appointmentDraft.PetId;

    this.appointmentService
      .getHistory(request)
      .pipe(
        map((res) => {
          res.History = res.History.sort((a, b) => {
            return (
              new Date(b.AppointmentDraft.StartDate).getTime() -
              new Date(a.AppointmentDraft.StartDate).getTime()
            );
          });
          res.History.map((item) => {
            item.AppointmentDraft.Anamnesis = item.AppointmentDraft.Anamnesis.sort(
              (a) => {
                if (
                  a.AnamnesisTypeId === AppointmentAnamnesisType.LiveHistory
                ) {
                  return -1;
                }
                return 0;
              }
            );
          });
          return res;
        }),
        untilDestroyed(this)
      )
      .subscribe((res) => {
        this.history = res.History;
        this.historyFiltered = copyArrayOfObject(res.History);
        this.isLoading = false;
        console.log('History', res);
      });
  }

  initSearch() {
    this.searchControl = new FormControl();
  }

  onSearchChange() {
    const filterValue = this.searchControl.value;
    const filtered = this.history.filter((d) => {
      let isFiltered = false;
      const startDate = moment(d.AppointmentDraft.StartDate).format(
        'DD.MM.YYYY'
      );
      if (startDate.includes(filterValue)) {
        isFiltered = true;
      }
      d.AppointmentDraft.Diagnosis.map((diagnose) => {
        if (diagnose.Name.toLowerCase().includes(filterValue.toLowerCase())) {
          isFiltered = true;
        }
      });
      return isFiltered;
    });
    this.historyFiltered = copyArrayOfObject(filtered);
  }

  ngOnDestroy() {}

  hasHealthFeatures(item: HealthFeatures): boolean {
    let check = false;
    for (const key of Object.keys(item)) {
      if (typeof item[key] === 'boolean' && item[key]) {
        check = true;
      }
    }
    return check;
  }

  getAnamnesisFromType(
    anamnesis: Anamnesis[],
    anamnesisType: AppointmentAnamnesisType
  ) {
    return anamnesis.filter((d) => d.AnamnesisTypeId === anamnesisType);
  }
}
