import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserService } from '../../shared/services/user.service';
import { Appointment } from '../../shared/interfaces/doctor/get-appointment';
import {
  ActivityMedicineModel,
  ActivityToMedicineModel,
  AdditionalUseModel,
  AnamnesisModel,
  AppointmentDraft,
  AppointmentDraftModel,
  AppointmentDraftResponse,
  AppointmentFormSection,
  PetVaccinationModel,
  DiagnosisModel,
  DiagnosticModel,
  DraftToDetailModel,
} from '../../shared/interfaces/appointments/appointments';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatIconRegistry, MatSnackBar } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { AppointmentService } from '../../shared/services/appointment.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { switchMap, timeout } from 'rxjs/operators';
import { api } from '../../../environments/api';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, EMPTY } from 'rxjs';
import { DialogService } from '../../shared/services/dialog.service';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-card-form',
  templateUrl: './card-form.component.html',
  styleUrls: ['./card-form.component.scss'],
})
export class CardFormComponent implements OnInit, OnDestroy {
  preloader = true;
  appointment: Appointment;
  appointmentDraft: AppointmentDraft;
  appointmentAfterSaveDraft: AppointmentDraft;
  appointmentResponse: AppointmentDraftResponse;
  init = true;

  testCounter = 1;

  notFound = false;

  fileControl: FormControl;

  updateTypeCreate;

  preparePetForm: AppointmentDraftModel;
  prepareDiagnosisForm: DiagnosisModel[] = [];
  prepareAnamnesisForm: AnamnesisModel[] = [];
  prepareDiagnosticForm: DiagnosticModel[] = [];
  prepareManipulationsForm: DraftToDetailModel[] = [];
  prepareActivityToMedicineForm: ActivityToMedicineModel[] = [];
  prepareActivityMedicineForm: ActivityMedicineModel[] = [];
  prepareAdditionalUseForm: AdditionalUseModel[] = [];
  preparePetVaccinationForm: PetVaccinationModel[] = [];

  subscribePrepareAll: Subscription;

  dateSubmitPetForm: Date;
  dateSubmitDiagnosisForm: Date;
  dateSubmitAnamnesisForm: Date;
  dateSubmitDiagnosticForm: Date;
  dateSubmitManipulationsForm: Date;
  dateSubmitActivityToMedicineForm: Date;
  dateSubmitActivityMedicineForm: Date;
  dateSubmitAdditionalUseForm: Date;
  dateSubmitPetVaccinationForm: Date;

  constructor(
    public userService: UserService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private fb: FormBuilder,
    private appointmentService: AppointmentService,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    private dialogService: DialogService
  ) {
    iconRegistry.addSvgIcon(
      'clock',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/clock.svg')
    );
    moment().locale('ru');
  }

  ngOnInit() {
    this.initForms();

    const id = this.route.snapshot.paramMap.get('id');

    if (id === '0') {
      const appointment = this.appointmentService.currentAppointment;
      if (!appointment) {
        this.router.navigate(['/account/appointments']);
        return;
      }
      const prepareModel = new AppointmentDraftModel();
      prepareModel.init();
      prepareModel.ActivitySchedulerAccount.Value = appointment.Id;
      prepareModel.PetId = appointment.PetId;
      prepareModel.UserName = appointment.SiteUserName;
      prepareModel.UserPhone = appointment.SPatient.Phone;
      prepareModel.Complaint.Value = appointment.Compliant;
      prepareModel.StartDate.Value = moment().format();
      prepareModel.Account.Value = appointment.AccountId;
      prepareModel.Specialist.Value = this.userService.getUserIdAndSecret().UserId;

      this.appointmentService
        .postAppointmentDraft(prepareModel)
        .subscribe((res: AppointmentDraftResponse) => {
          console.log('res post', res);
          if (res.Success) {
            this.updateTypeCreate = true;
            this.appointmentDraft = res.AppointmentDraft;
            this.appointmentResponse = res;
            this.preloader = false;
            this.router.navigate(['card', res.AppointmentDraft.Id]);
          }
        });
    } else {
      this.appointmentService
        .getAppointment(id)
        .subscribe((res: AppointmentDraftResponse) => {
          console.log('res get', res);
          if (res.Success) {
            this.updateTypeCreate = false;
            this.appointmentDraft = res.AppointmentDraft;
            this.appointmentResponse = res;
            this.preloader = false;
          }
        });
    }
  }

  initForms() {
    this.fileControl = this.fb.control([]);
  }

  getControls(form, group: string) {
    return form.get(group).controls;
  }

  saveDraft(form?: AppointmentFormSection) {
    const prepareModel = this.prepareModel(form);
    console.log('prepareModel', prepareModel);
    this.appointmentService
      .postAppointmentDraft(prepareModel)
      .pipe(timeout(api.requestTimeout), untilDestroyed(this))
      .subscribe((res: AppointmentDraftResponse) => {
        console.log('after save', res);

        if (!this.init) {
          this.appointmentAfterSaveDraft = res.AppointmentDraft;
          this.snackBar.open('Сохранено', 'ОК', {
            duration: 2000,
          });
        } else {
          this.init = false;
        }
      });
  }

  saveDraftAll() {
    this.dateSubmitPetForm = new Date();
    this.dateSubmitDiagnosisForm = new Date();
    this.dateSubmitAnamnesisForm = new Date();
    this.dateSubmitDiagnosticForm = new Date();
    this.dateSubmitManipulationsForm = new Date();
    this.dateSubmitActivityToMedicineForm = new Date();
    this.dateSubmitActivityMedicineForm = new Date();
    this.dateSubmitAdditionalUseForm = new Date();
    this.dateSubmitPetVaccinationForm = new Date();

    this.subscribePrepareAll = combineLatest([
      this.appointmentService.preparePetFormEmit,
      this.appointmentService.prepareDiagnosisFormEmit,
      this.appointmentService.prepareAnamnesisFormEmit,
      this.appointmentService.prepareDiagnosticFormEmit,
      this.appointmentService.prepareManipulationsFormEmit,
      this.appointmentService.prepareActivityToMedicineFormEmit,
      this.appointmentService.prepareActivityMedicineFormEmit,
      this.appointmentService.prepareAdditionalUseFormEmit,
      this.appointmentService.preparePetVaccinationFormEmit,
    ])
      .pipe(
        switchMap((res) => {
          if (res[0]) {
            this.preparePetForm = this.appointmentService.preparePetForm;
          }
          if (res[1]) {
            this.prepareDiagnosisForm = this.appointmentService.prepareDiagnosisForm;
          }
          if (res[2]) {
            this.prepareAnamnesisForm = this.appointmentService.prepareAnamnesisForm;
          }
          if (res[3]) {
            this.prepareDiagnosticForm = this.appointmentService.prepareDiagnosticForm;
          }
          if (res[4]) {
            this.prepareManipulationsForm = this.appointmentService.prepareManipulationsForm;
          }
          if (res[5]) {
            this.prepareActivityToMedicineForm = this.appointmentService.prepareActivityToMedicineForm;
          }
          if (res[6]) {
            this.prepareActivityMedicineForm = this.appointmentService.prepareActivityMedicineForm;
          }
          if (res[7]) {
            this.prepareAdditionalUseForm = this.appointmentService.prepareAdditionalUseForm;
          }
          if (res[8]) {
            this.preparePetVaccinationForm = this.appointmentService.preparePetVaccinationForm;
          }

          const _model: AppointmentDraftModel = JSON.parse(
            JSON.stringify(this.preparePetForm)
          );

          _model.Id = this.appointmentDraft.Id;
          _model.PetId = this.appointmentDraft.PetId;
          _model.UserName = this.appointmentResponse.UserName;
          _model.UserPhone = this.appointmentResponse.UserPhone;
          _model.Complaint.Value = this.appointmentDraft.Complaint;
          _model.ActivitySchedulerAccount.Value = this.appointmentDraft.ActivitySchedulerAccountId;
          _model.Diagnosis = this.prepareDiagnosisForm;
          _model.Anamnesis = this.prepareAnamnesisForm;
          _model.Diagnostic = this.prepareDiagnosticForm;
          _model.DraftToDetail = this.prepareManipulationsForm;
          _model.ActivityToMedicine = this.prepareActivityToMedicineForm;
          _model.ActivityMedicine = this.prepareActivityMedicineForm;
          _model.AdditionalUse = this.prepareAdditionalUseForm;
          _model.BtcPetVaccination = this.preparePetVaccinationForm;

          return this.appointmentService.postAppointmentDraft(_model);
        })
      )
      .subscribe((res) => {
        console.log(res);
        if (!this.init) {
          this.appointmentAfterSaveDraft = res.AppointmentDraft;
          this.snackBar.open('Сохранено', 'ОК', {
            duration: 2000,
          });
        } else {
          this.init = false;
        }
        this.subscribePrepareAll.unsubscribe();
      });
  }

  checkSave() {
    this.dialogService
      .openSubmitDialog(
        'Подтвердите действие!',
        'Вы действительно хотите завершить прием? После этого дальнейшее изменение будет не возможно.'
      )
      .subscribe((response) => {
        if (response) {
          this.save();
        }
      });
  }

  save(form?: AppointmentFormSection) {
    const prepareModel = this.prepareModel(form);
    console.log('prepareModel', prepareModel);
    this.appointmentService
      .postAppointmentDraft(prepareModel)
      .pipe(
        timeout(api.requestTimeout),
        switchMap((res: AppointmentDraftResponse) => {
          if (res.Success) {
            return this.appointmentService.saveAppointment(
              this.appointmentDraft.Id
            );
          } else {
            return EMPTY;
          }
        }),
        untilDestroyed(this)
      )
      .subscribe((res) => {
        console.log('close appointment', res);
        this.router.navigate(['account', 'appointments']);
      });
  }

  prepareModel(form?: AppointmentFormSection) {
    console.log(this.preparePetForm);
    const _model: AppointmentDraftModel = JSON.parse(
      JSON.stringify(this.preparePetForm)
    );

    _model.Id = this.appointmentDraft.Id;
    _model.PetId = this.appointmentDraft.PetId;
    _model.UserName = this.appointmentResponse.UserName;
    _model.UserPhone = this.appointmentResponse.UserPhone;
    _model.Complaint.Value = this.appointmentDraft.Complaint;
    _model.ActivitySchedulerAccount.Value = this.appointmentDraft.ActivitySchedulerAccountId;

    if (form) {
      switch (form) {
        case AppointmentFormSection.Diagnosis:
          console.log('Diagnosis');
          _model.Diagnosis = this.prepareDiagnosisForm;
          break;
        case AppointmentFormSection.Anamnesis:
          console.log('Anamnesis');
          _model.Anamnesis = this.prepareAnamnesisForm;
          break;
        case AppointmentFormSection.Diagnostic:
          console.log('Diagnostic');
          _model.Diagnostic = this.prepareDiagnosticForm;
          break;
        case AppointmentFormSection.Manipulations:
          console.log('Manipulations');
          _model.DraftToDetail = this.prepareManipulationsForm;
          break;
        case AppointmentFormSection.ActivityToMedicine:
          console.log('ActivityToMedicine');
          _model.ActivityToMedicine = this.prepareActivityToMedicineForm;
          break;
        case AppointmentFormSection.ActivityMedicine:
          console.log('ActivityMedicine');
          _model.ActivityMedicine = this.prepareActivityMedicineForm;
          break;
        case AppointmentFormSection.AdditionalUse:
          console.log('AdditionalUse');
          _model.AdditionalUse = this.prepareAdditionalUseForm;
          break;
        case AppointmentFormSection.PetVaccination:
          console.log('PetVaccination');
          _model.BtcPetVaccination = this.preparePetVaccinationForm;
          break;
      }
    } else {
      console.log('All');
      _model.Diagnosis = this.prepareDiagnosisForm;
      _model.Anamnesis = this.prepareAnamnesisForm;
      _model.Diagnostic = this.prepareDiagnosticForm;
      _model.DraftToDetail = this.prepareManipulationsForm;
      _model.ActivityToMedicine = this.prepareActivityToMedicineForm;
      _model.ActivityMedicine = this.prepareActivityMedicineForm;
      _model.AdditionalUse = this.prepareAdditionalUseForm;
      _model.BtcPetVaccination = this.preparePetVaccinationForm;
    }

    return _model;
  }

  onSubmitPetForm(event) {
    this.preparePetForm = event;
    if (this.preparePetForm) {
      this.saveDraft(AppointmentFormSection.Pet);
    }
  }

  onSubmitDiagnosisForm(event) {
    this.prepareDiagnosisForm = event;
    if (this.preparePetForm) {
      this.saveDraft(AppointmentFormSection.Diagnosis);
    }
  }

  onSubmitAnamnesisForm(event) {
    this.prepareAnamnesisForm = event;
    if (this.preparePetForm) {
      this.saveDraft(AppointmentFormSection.Anamnesis);
    }
  }

  onSubmitDiagnosticForm(event) {
    this.prepareDiagnosticForm = event;
    if (this.preparePetForm) {
      this.saveDraft(AppointmentFormSection.Diagnostic);
    }
  }

  onSubmitManipulationsForm(event) {
    this.prepareManipulationsForm = event;
    if (this.preparePetForm) {
      this.saveDraft(AppointmentFormSection.Manipulations);
    }
  }

  onSubmitActivityToMedicineForm(event) {
    this.prepareActivityToMedicineForm = event;
    if (this.preparePetForm) {
      this.saveDraft(AppointmentFormSection.ActivityToMedicine);
    }
  }

  onSubmitActivityMedicineForm(event) {
    this.prepareActivityMedicineForm = event;
    if (this.preparePetForm) {
      this.saveDraft(AppointmentFormSection.ActivityMedicine);
    }
  }

  onSubmitAdditionalUseForm(event) {
    this.prepareAdditionalUseForm = event;
    if (this.preparePetForm) {
      this.saveDraft(AppointmentFormSection.AdditionalUse);
    }
  }

  onSubmitPetVaccinationForm(event) {
    this.preparePetVaccinationForm = event;
    if (this.preparePetForm) {
      this.saveDraft(AppointmentFormSection.PetVaccination);
    }
  }

  ngOnDestroy() {}
}
