import { Component, OnDestroy, OnInit } from '@angular/core';
import * as moment from 'moment';
import 'moment/locale/ru';
import { api } from '../../../environments/api';
import { DoctorService } from '../../shared/services/doctor.service';
import {
  Appointment,
  AppointmentGrid,
  GetAppointmentDraft,
  GetAppointmentResult,
  ScheduleDates,
} from '../../shared/interfaces/doctor/get-appointment';
import { DateChangerDateRangeType } from '../../shared/interfaces/appointments/date-changer-date-range-type';
import {
  dateCompilationNoUtc,
  dateEncodeNoUtc,
  getAvatar,
} from '../../shared/functions/functions';
import { switchMap, timeout } from 'rxjs/operators';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { extendMoment } from 'moment-range';
import { SelectInterface } from '../../shared/interfaces/select.interface';
import { typeAppointment, typeId } from '../../shared/catalogs/clinic.catalogs';
import { AppointmentInfoDialogComponent } from './appointment-info-dialog/appointment-info-dialog.component';
import { MatDialog, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { AppointmentService } from '../../shared/services/appointment.service';
import {
  AppointmentStatusEnum,
  SwitchAppointmentResponse,
  SwitchAppointmentSchedule,
} from '../../shared/interfaces/appointments/appointments';
import { EMPTY } from 'rxjs';
import { DialogService } from '../../shared/services/dialog.service';

@Component({
  selector: 'app-account-appointment',
  templateUrl: './account-appointment.component.html',
  styleUrls: ['./account-appointment.component.scss'],
})
export class AccountAppointmentComponent implements OnInit, OnDestroy {
  preloader = false;
  startDate;
  dueDate;
  rangeType;
  changeToDay;
  appointmentGrid: AppointmentGrid = {};
  appointmentDayGrid: Appointment[] = [];
  appointmentsType: SelectInterface[] = [...typeId];
  preloaderCalendar = true;
  appointmentStatusEnum = AppointmentStatusEnum;

  constructor(
    private doctorService: DoctorService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private appointmentService: AppointmentService,
    private dialogService: DialogService
  ) {
    moment().locale('ru');
  }

  ngOnInit() {}

  getDates(dates: DateChangerDateRangeType) {
    if (!api.production) {
      console.log('dates', dates);
    }

    this.startDate = dates.dates[0];
    this.dueDate = dates.dates[1];
    this.rangeType = dates.type;
    this.preloaderCalendar = true;

    const data: ScheduleDates = {
      StartDate: dateCompilationNoUtc(dates.dates[0]),
      DueDate: dateCompilationNoUtc(dates.dates[1]),
    };

    if (this.rangeType === 'month') {
      this.createCalendarGrid();
    } else {
      this.appointmentGrid = {};
      this.appointmentDayGrid = [];
    }

    this.doctorService
      .getAppointment(data)
      .pipe(timeout(api.requestTimeout), untilDestroyed(this))
      .subscribe((response: GetAppointmentResult) => {
        if (!api.production) {
          console.log('GetAppointmentResult', response);
        }

        if (response.GetAppointmentResult.Success) {
          const appointments = response.GetAppointmentResult.Schedule;

          this.changeToDay = null;

          appointments.forEach((item) => {
            if (this.rangeType === 'month') {
              this.createCalendar(item);
            } else {
              this.createDayGrid(item);
            }
          });

          if (!api.production) {
            console.log('appointmentGrid', this.appointmentGrid);
          }
          if (!api.production) {
            console.log('appointmentDayGrid', this.appointmentDayGrid);
          }

          this.preloaderCalendar = false;
        }
      });
  }

  goToDay(date) {
    this.changeToDay = date;
  }

  createCalendarGrid() {
    this.appointmentGrid = {};
    this.appointmentDayGrid = [];

    let startDay = moment(this.startDate);
    let endDay = moment(this.dueDate);

    if (startDay.weekday() !== 0) {
      startDay = startDay.weekday(0);
    }

    if (endDay.weekday() !== 6) {
      endDay = endDay.weekday(6);
    }

    const eMoment = extendMoment(moment);

    const dateRange = Array.from(eMoment.range(startDay, endDay).by('days'));

    dateRange.forEach((date) => {
      const unixDate = date.format('X');
      const unixStartDate = this.startDate.format('X');
      const unixDueDate = this.dueDate.format('X');
      const startCurrentDate = moment
        .unix(parseInt(unixDate, 10))
        .startOf('day')
        .format('X');

      this.appointmentGrid[unixDate] = {};
      this.appointmentGrid[unixDate].empty = true;
      this.appointmentGrid[unixDate].today =
        unixDate === moment().startOf('day').format('X');
      this.appointmentGrid[unixDate].date = unixDate;

      this.appointmentGrid[unixDate].currentMonth =
        startCurrentDate >= unixStartDate && startCurrentDate <= unixDueDate;
    });
  }

  createCalendar(appointment: Appointment) {
    const key = moment(dateEncodeNoUtc(appointment.StartDate))
      .startOf('day')
      .format('X');
    if (!api.production) {
      console.log('key', key);
    }
    if (!api.production) {
      console.log('hasOwnProperty', this.appointmentGrid.hasOwnProperty(key));
    }
    this.appointmentGrid[key].empty = false;
    // this.appointmentGrid[key].date = key;

    if (!this.appointmentGrid[key].hasOwnProperty('appointments')) {
      this.appointmentGrid[key].appointments = [];
    }

    if (!this.appointmentGrid[key].hasOwnProperty('types')) {
      this.appointmentGrid[key].types = [];
    }

    if (!this.appointmentGrid[key].hasOwnProperty('outer')) {
      this.appointmentGrid[key].outer = false;
    }

    if (appointment.PlaceAppointmentId === typeAppointment[1].value) {
      this.appointmentGrid[key].outer = true;
    }

    this.appointmentGrid[key].appointments.push(appointment);

    const type = this.getType(appointment.TypeId);

    if (this.appointmentGrid[key].types.indexOf(type) === -1) {
      this.appointmentGrid[key].types.push(type);
    }
  }

  createDayGrid(appointment: Appointment) {
    appointment.DecodeInfo = {};

    appointment.DecodeInfo.time = moment(
      dateEncodeNoUtc(appointment.StartDate)
    ).format('HH:mm');

    appointment.DecodeInfo.typeName = this.appointmentsType.find(
      (item) => item.value === appointment.TypeId
    ).name;
    appointment.DecodeInfo.type = this.getType(appointment.TypeId);
    appointment.DecodeInfo.textDate = moment(
      dateEncodeNoUtc(appointment.StartDate)
    ).format('DD MMMM YYYY в HH:mm (ddd)');

    const userPhoto = appointment.SPatient.Photoes;

    if (userPhoto) {
      appointment.DecodeInfo.userImage = getAvatar(userPhoto);
    }

    const clinicPhoto = appointment.ClinicPhotoes;

    if (clinicPhoto) {
      appointment.DecodeInfo.clinicImage = getAvatar(clinicPhoto);
    }

    const petPhoto = appointment.SPatient.Pet.Files;

    if (petPhoto) {
      appointment.DecodeInfo.petImage = getAvatar(petPhoto);
    }

    if (appointment.PlaceAppointmentId === typeAppointment[1].value) {
      appointment.DecodeInfo.outer = true;
    }

    this.appointmentDayGrid.push(appointment);
  }

  getDay(date) {
    return moment.unix(parseInt(date, 10)).format('DD');
  }

  getType(item) {
    let type;

    switch (item) {
      case this.appointmentsType[0].value:
        type = 'Х';
        break;
      case this.appointmentsType[1].value:
        type = 'МК';
        break;
      case this.appointmentsType[2].value:
        type = 'МЛ';
        break;
      case this.appointmentsType[3].value:
        type = 'И';
        break;
      case this.appointmentsType[4].value:
        type = 'В';
        break;
      case this.appointmentsType[5].value:
        type = 'З';
        break;
      case this.appointmentsType[6].value:
        type = 'С';
        break;
      case this.appointmentsType[7].value:
        type = 'П';
        break;
      case this.appointmentsType[8].value:
        type = 'ВП';
        break;
    }

    return type;
  }

  showAppointmentInfo(appointment) {
    const dialogRef = this.dialog.open(AppointmentInfoDialogComponent, {
      data: {
        appointment,
      },
      panelClass: 'record-clinic-dialog',
    });

    dialogRef.afterClosed().subscribe((response) => {});
  }

  copyText(text) {
    const el = document.createElement('textarea');
    el.value = text;
    document.body.appendChild(el);
    el.select();
    el.setSelectionRange(0, text.length);
    document.execCommand('copy');
    document.body.removeChild(el);
    this.snackBar.open('Адресс скопирован', 'Ок', {
      duration: 2000,
    });
  }

  startAppointment(item) {
    this.appointmentService.currentAppointment = item;
    this.preloader = true;

    this.appointmentService
      .getAppointmentDraft(item.Id)
      .pipe(
        switchMap((res: GetAppointmentDraft[]) => {
          if (!api.production) {
            console.log('getAppointmentDraft', res);
          }
          if (res.length > 0) {
            this.router.navigate(['/card/' + res[0].Id]);
            return EMPTY;
          } else {
            const schedule = new SwitchAppointmentSchedule();
            schedule.Id = item.Id;
            schedule.StatusId = AppointmentStatusEnum.Progress;
            return this.appointmentService.switchAppointment(schedule);
          }
        })
      )
      .subscribe(
        (res: SwitchAppointmentResponse) => {
          if (res.SwitchAppointmentResult.Success) {
            this.router.navigate(['/card/' + 0]);
          } else {
            this.dialogService.showStandardError();
          }
        },
        () => {
          this.dialogService.showStandardError();
        }
      );
  }

  ngOnDestroy() {}
}
