import { SelectInterface } from '../interfaces/select.interface';

export const anamnesisType: SelectInterface[] = [
  { value: 'b4888041-2ffb-4985-b06d-25f87acddf4c', name: 'Анамнез жизни' },
  {
    value: '1f86011f-5819-4dd9-a47e-d674e7f9fc54',
    name: 'Анамнез заболевания',
  },
];

export const diagnosisType: SelectInterface[] = [
  { value: '50c952a9-1cfc-4921-bb70-1f52da5afe2d', name: 'Вероятный' },
  { value: '9b744137-e522-45ce-ab4f-f477ee590379', name: 'Дифференциальный' },
  { value: 'b2873d6e-dd21-47a0-831a-e172deb22ae6', name: 'Окончательный' },
  { value: '09557c95-cedf-4919-9d12-619e0998df84', name: 'Предварительный' },
];

export const frequency: SelectInterface[] = [
  { value: 'f256a5ac-12d1-49e5-b16a-ea590d252098', name: 'В день' },
  { value: '384d0816-3761-4a49-9aad-67e4c50f3456', name: 'В неделю' },
  { value: '20eb3296-4744-413d-94d2-2486c0ea688c', name: 'В месяц' },
  { value: '92af06ff-1ad3-45e0-a268-679c3bb4f056', name: 'В год' },
  { value: '598d65c9-63e8-44ce-8288-f8633fc808ff', name: 'В год' },
  { value: 'df6877c5-e887-45b0-9d53-50d5d04640ba', name: 'В день' },
  { value: '2713b3ac-e26a-497c-b3c6-c970aa89a4bf', name: 'В месяц' },
  { value: '05e301f0-420d-48a8-a477-53fd40ee25ea', name: 'В неделю' },
];

export const vaccinationCategory: SelectInterface[] = [
  { value: 'f211ec6a-6c84-4ff9-9e25-a282c5ff3154', name: 'Первичная' },
  { value: '36540f95-e87d-4a00-bc4a-38aa631263eb', name: 'Вторичная' },
  { value: '17c8af15-5187-4f9d-8281-b9be0da29b79', name: 'Вторичная' },
  { value: 'd9720b1a-4f0c-489a-8dd1-867b341224c0', name: 'Первичная' },
];

export const vaccinationStatus: SelectInterface[] = [
  { value: 'ab25d50a-f68a-46a2-ad0e-26e72154a0a3', name: 'Запланирована' },
  { value: '83bd15f9-bc68-40f0-bfb5-6fbacc9ad196', name: 'Выполнена' },
  { value: 'ed8a09b2-de29-4cab-9008-072d219e1186', name: 'Не выполнена' },
  { value: 'b4083cb5-1523-4c20-81c5-918ca0b0721c', name: 'Выполнена' },
  { value: '57666391-f55e-46a3-81be-735ebcccf6c7', name: 'Запланирована' },
  { value: 'bdca52ef-5ce3-4910-8a5b-9b92f8397a19', name: 'Не выполнена' },
];
