import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {
  AnamnesisCategories,
  AnamnesisCategoryModel,
  AnamnesisModel,
  AnamnesisQuestionModel,
  AnamnesisQuestions,
  AppointmentAnamnesisType,
  AppointmentDraft,
} from '../../../shared/interfaces/appointments/appointments';
import { anamnesisType } from '../../../shared/catalogs/appointment.catalog';
import { AppointmentService } from '../../../shared/services/appointment.service';

@Component({
  selector: 'app-anamnesis-form',
  templateUrl: './anamnesis-form.component.html',
  styleUrls: ['./anamnesis-form.component.scss'],
})
export class AnamnesisFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() appointmentDraft: AppointmentDraft;
  @Input() appointmentAfterSaveDraft: AppointmentDraft;
  @Input() updateTypeCreate: boolean;
  @Input()
  set submit(dateSubmit: Date) {
    if (dateSubmit) {
      this.onPrepare();
    }
  }
  @Output() submitted = new EventEmitter();

  @ViewChildren('liveHistoryCategory')
  liveHistoryCategory: QueryList<ElementRef>;
  @ViewChildren('medicalHistoryCategory')
  medicalHistoryCategory: QueryList<ElementRef>;
  @ViewChildren('liveHistoryQuestion')
  liveHistoryQuestion: QueryList<ElementRef>;
  @ViewChildren('medicalHistoryQuestion')
  medicalHistoryQuestion: QueryList<ElementRef>;

  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private appointmentService: AppointmentService
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      liveHistoryId: [],
      liveHistory: this.fb.array([]),
      medicalHistory: this.fb.array([]),
      medicalHistoryId: [],
      liveHistoryFree: new FormControl(),
      medicalHistoryFree: new FormControl(),
    });

    if (this.appointmentDraft !== undefined) {
      if (!this.updateTypeCreate) {
        this.updateFormAfterGet();
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.appointmentDraft &&
      this.appointmentDraft !== undefined &&
      this.form
    ) {
      if (!this.updateTypeCreate) {
        this.updateFormAfterGet();
      }
    }

    if (
      changes.appointmentAfterSaveDraft &&
      this.appointmentAfterSaveDraft !== undefined &&
      this.form
    ) {
      this.updateIds();
    }
  }

  updateFormAfterGet() {
    const liveHistory = this.form.get('liveHistory');
    this.appointmentDraft.Anamnesis.map((d) => {
      if (d.AnamnesisTypeId === AppointmentAnamnesisType.LiveHistory) {
        this.form.patchValue({
          liveHistoryFree: d.Description,
          liveHistoryId: d.Id,
        });
        const categories = [];
        d.AnamnesisCategories.map((x) => {
          const category = new AnamnesisCategories();
          category.Id = x.Id;
          category.Name = x.Name;
          category.AnamnesisQuestions = x.AnamnesisQuestions;

          this.addAnamnesisCategoryModel(this.form, 'liveHistory', category);
        });
      }
      if (d.AnamnesisTypeId === AppointmentAnamnesisType.MedicalHistory) {
        this.form.patchValue({
          medicalHistoryFree: d.Description,
          medicalHistoryId: d.Id,
        });
        const categories = [];
        d.AnamnesisCategories.map((x) => {
          const category = new AnamnesisCategories();
          category.Id = x.Id;
          category.Name = x.Name;
          category.AnamnesisQuestions = x.AnamnesisQuestions;

          this.addAnamnesisCategoryModel(this.form, 'medicalHistory', category);
        });
      }
    });
  }

  updateIds() {
    const liveHistory = this.appointmentAfterSaveDraft.Anamnesis.find(
      (d) => d.AnamnesisTypeId === anamnesisType[0].value
    );
    if (liveHistory) {
      this.form.patchValue({ liveHistoryId: liveHistory.Id });
      liveHistory.AnamnesisCategories.map((d, i) => {
        const controls = this.form.get('liveHistory') as FormArray;
        const control = controls.at(i);
        if (control) {
          control.patchValue({ id: d.Id });

          d.AnamnesisQuestions.map((x, j) => {
            const questions = controls.at(i).get('questions') as FormArray;
            questions.at(j).patchValue({ id: x.Id });
          });
        }
      });
    }
    const medicalHistory = this.appointmentAfterSaveDraft.Anamnesis.find(
      (d) => d.AnamnesisTypeId === anamnesisType[1].value
    );
    if (medicalHistory) {
      this.form.patchValue({ medicalHistoryId: medicalHistory.Id });
      medicalHistory.AnamnesisCategories.map((d, i) => {
        const controls = this.form.get('medicalHistory') as FormArray;
        const control = controls.at(i);
        if (control) {
          control.patchValue({ id: d.Id });
          d.AnamnesisQuestions.map((x, j) => {
            const questions = controls.at(i).get('questions') as FormArray;
            questions.at(j).patchValue({ id: x.Id });
          });
        }
      });
    }
  }

  addAnamnesisCategory(form, group: string, focusInput) {
    const controls = form.get(group) as FormArray;
    controls.push(
      this.fb.group({
        id: [],
        category: [],
        questions: this.fb.array([]),
      })
    );

    this.changeDetectorRef.detectChanges();
    focusInput.last.nativeElement.focus();
  }

  addAnamnesisCategoryModel(form, group: string, model?: AnamnesisCategories) {
    const controls = form.get(group) as FormArray;
    const questions = [];
    if (model.AnamnesisQuestions.length) {
      model.AnamnesisQuestions.map((d) => {
        const question = new AnamnesisQuestions();
        question.Id = d.Id;
        question.Question = d.Question;
        question.Answer = d.Answer;

        questions.push(this.addAnamnesisQuestionModel(question));
      });
    }
    controls.push(
      this.fb.group({
        id: model.Id,
        category: model.Name,
        questions: this.fb.array(questions),
      })
    );
  }

  addAnamnesisQuestion(form, focusInput) {
    const controls = form.controls.questions as FormArray;
    controls.push(
      this.fb.group({
        id: [],
        question: [],
        answer: [],
      })
    );

    this.changeDetectorRef.detectChanges();
    focusInput.last.nativeElement.focus();
  }

  addAnamnesisQuestionModel(model: AnamnesisQuestions) {
    return this.fb.group({
      id: model.Id,
      question: model.Question,
      answer: model.Answer,
    });
  }

  getControls(form, group: string) {
    return form.get(group).controls;
  }

  removeFormGroup(form, group: string, i) {
    const controls = form.get(group) as FormArray;
    controls.removeAt(i);
  }

  onSubmit() {
    const prepare = this.prepareForm();
    this.submitted.emit(prepare);
  }

  onPrepare() {
    this.appointmentService.prepareAnamnesisForm = this.prepareForm();
  }

  prepareForm() {
    const controls = this.form.controls;
    const _model: AnamnesisModel[] = [];

    const liveHistory = new AnamnesisModel();
    liveHistory.init();
    if (controls.liveHistoryId.value) {
      liveHistory.Id = controls.liveHistoryId.value;
    }
    liveHistory.AnamnesisType.Value = AppointmentAnamnesisType.LiveHistory;
    liveHistory.Description.Value = controls.liveHistoryFree.value;
    liveHistory.AnamnesisCategory = [];

    this.prepareAnamnesisType(
      controls.liveHistory.value,
      liveHistory.AnamnesisCategory
    );

    _model.push(liveHistory);

    const medicalHistory = new AnamnesisModel();
    medicalHistory.init();
    if (controls.medicalHistoryId.value) {
      medicalHistory.Id = controls.medicalHistoryId.value;
    }
    medicalHistory.AnamnesisType.Value =
      AppointmentAnamnesisType.MedicalHistory;
    medicalHistory.Description.Value = controls.medicalHistoryFree.value;
    medicalHistory.AnamnesisCategory = [];

    this.prepareAnamnesisType(
      controls.medicalHistory.value,
      medicalHistory.AnamnesisCategory
    );

    _model.push(medicalHistory);

    return _model;
  }

  prepareAnamnesisType(anamnesis, anamnesisCategory) {
    anamnesis.map((category) => {
      const newCategory = new AnamnesisCategoryModel();
      newCategory.init();
      if (category.id) {
        newCategory.Id = category.id;
      }
      newCategory.Name.Value = category.category;
      newCategory.AnamnesisQuestion = [];

      category.questions.map((question) => {
        const newQuestion = new AnamnesisQuestionModel();
        newQuestion.init();
        if (question.id) {
          newQuestion.Id = question.id;
        }
        newQuestion.Question.Value = question.question;
        newQuestion.Answer.Value = question.answer;
        newCategory.AnamnesisQuestion.push(newQuestion);
      });

      anamnesisCategory.push(newCategory);
    });
  }

  ngOnDestroy() {}
}
