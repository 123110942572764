import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import * as moment from 'moment';
import { DateChangerDateRangeType } from '../../interfaces/appointments/date-changer-date-range-type';
import { api } from '../../../../environments/api';

@Component({
  selector: 'app-date-changer',
  templateUrl: './date-changer.component.html',
  styleUrls: ['./date-changer.component.scss'],
})
export class DateChangerComponent implements OnInit, OnChanges {
  currentWeek: boolean = false;
  currentMonth: boolean = false;
  currentDay: boolean = false;
  startDate = moment().startOf('day');
  dueDate = moment().endOf('day');
  dayDateFormat;
  startDateFormat;
  dueDateFormat;
  monthDateFormat;
  rangeDay;
  rangeWeek;
  rangeMonth;

  @Input() init: boolean = false;
  @Input() ranges: string[] = ['day', 'week', 'month'];
  @Input() dateRangeType = 'day';
  @Input() changeToDay;
  @Output()
  changeDate: EventEmitter<DateChangerDateRangeType> = new EventEmitter();

  constructor() {}

  ngOnInit() {
    this.rangeDay = this.ranges.includes('day');
    this.rangeWeek = this.ranges.includes('week');
    this.rangeMonth = this.ranges.includes('month');

    switch (this.dateRangeType) {
      case 'day':
        this.changeDayDates();
        this.currentDay = true;
        break;
      case 'week':
        this.changeWeekDates();
        this.currentWeek = true;
        this.startDate = moment().startOf('day');
        this.dueDate = moment().add(6, 'days').endOf('day');
        break;
      case 'month':
        this.changeMonthDates();
        this.currentMonth = true;
        this.startDate = moment().startOf('month');
        this.dueDate = moment().endOf('month');
        break;
      default:
        this.changeDayDates();
        this.currentDay = true;
        break;
    }

    if (this.init) {
      this.emitDates();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    !api.production ? console.log('changes', changes) : null;
    if (changes.changeToDay && changes.changeToDay.currentValue) {
      this.goToDay(changes.changeToDay.currentValue);
    }
  }

  emitDates() {
    this.changeDate.emit({
      dates: [this.startDate, this.dueDate],
      type: this.dateRangeType,
    });
  }

  prevDay() {
    this.startDate.subtract(1, 'days').startOf('day');
    this.dueDate.subtract(1, 'days').endOf('day');
    this.emitDates();
    this.changeDayDates();
  }

  nextDay() {
    this.startDate.add(1, 'days').startOf('day');
    this.dueDate.add(1, 'days').endOf('day');
    this.emitDates();
    this.changeDayDates();
  }

  goCurrentDay() {
    if (!this.currentDay) {
      this.startDate = moment().startOf('day');
      this.dueDate = moment().endOf('day');
      this.emitDates();
      this.changeDayDates();
    }
  }

  goToDay(date) {
    this.dateRangeType = 'day';
    this.startDate = moment.unix(date).startOf('day');
    this.dueDate = moment.unix(date).endOf('day');
    if (this.startDate === moment().startOf('day')) {
      !api.production ? console.log('current', true) : null;
      this.currentDay = true;
    } else {
      this.currentDay = false;
    }
    this.emitDates();
    this.changeDayDates();
  }

  prevWeek() {
    this.startDate.subtract(7, 'days').startOf('day');
    this.dueDate.subtract(7, 'days').endOf('day');
    this.emitDates();
    this.changeWeekDates();
  }

  nextWeek() {
    this.startDate.add(7, 'days').startOf('day');
    this.dueDate.add(7, 'days').endOf('day');
    this.emitDates();
    this.changeWeekDates();
  }

  goCurrentWeek() {
    if (!this.currentWeek) {
      this.startDate = moment().startOf('day');
      this.dueDate = moment().add(6, 'days').endOf('day');
      this.emitDates();
      this.changeWeekDates();
    }
  }

  prevMonth() {
    this.startDate.subtract(1, 'month').startOf('month');
    this.dueDate.subtract(1, 'month').endOf('month');
    this.emitDates();
    this.changeMonthDates();
  }

  nextMonth() {
    this.startDate.add(1, 'month').startOf('month');
    this.dueDate.add(1, 'month').endOf('month');
    this.emitDates();
    this.changeMonthDates();
  }

  goCurrentMonth() {
    if (!this.currentMonth) {
      this.startDate = moment().startOf('month');
      this.dueDate = moment().endOf('month');
      this.emitDates();
      this.changeMonthDates();
    }
  }

  changeDayDates() {
    this.dayDateFormat = this.startDate.format('DD MMMM YYYY');
    this.currentDay =
      this.startDate.format('DD.MM.YYYY') === moment().format('DD.MM.YYYY');
  }

  changeWeekDates() {
    if (this.startDate.format('MM') === this.dueDate.format('MM')) {
      this.startDateFormat = this.startDate.format('DD');
      this.dueDateFormat = this.dueDate.format('DD MMMM YYYY');
    } else if (this.startDate.format('YYYY') != this.dueDate.format('YYYY')) {
      this.startDateFormat = this.startDate.format('DD MMMM YYYY');
      this.dueDateFormat = this.dueDate.format('DD MMMM YYYY');
    } else {
      this.startDateFormat = this.startDate.format('DD MMMM');
      this.dueDateFormat = this.dueDate.format('DD MMMM YYYY');
    }

    this.currentWeek =
      this.startDate.format('DD.MM.YYYY') === moment().format('DD.MM.YYYY');
  }

  changeMonthDates() {
    this.monthDateFormat = this.startDate.format('MMMM YYYY');

    this.currentMonth =
      this.startDate.format('MM.YYYY') === moment().format('MM.YYYY');
  }

  changeRangeDates(dateRangeType) {
    this.dateRangeType = dateRangeType;

    switch (dateRangeType) {
      case 'day':
        this.currentDay = false;
        this.goCurrentDay();
        break;
      case 'week':
        this.currentWeek = false;
        this.goCurrentWeek();
        break;
      case 'month':
        this.currentMonth = false;
        this.goCurrentMonth();
        break;
    }
  }
}
