import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../shared/services/user.service';
import { DialogService } from '../shared/services/dialog.service';
import { PreSwitchRelationTypeInterface } from '../shared/interfaces/user/switch-relation-type/pre-switch-relation-type.interface';
import { api } from '../../environments/api';
import { timeout } from 'rxjs/operators';
import { SwitchRelationTypeResponseInterface } from '../shared/interfaces/user/switch-relation-type/switch-relation-type-response.interface';

@Component({
  selector: 'app-clinic-denie',
  templateUrl: './clinic-denie.component.html',
  styleUrls: ['./clinic-denie.component.scss'],
})
export class ClinicDenieComponent implements OnInit {
  clinicId: string;
  switchRelationTypeSubscribe: Subscription;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private dialogService: DialogService,
    private router: Router
  ) {}

  ngOnInit() {
    this.clinicId = this.route.snapshot.queryParamMap.get('id');

    if (this.clinicId) {
      this.switchType();
    } else {
      this.router.navigate(['/account']);
    }
  }

  switchType() {
    const request: PreSwitchRelationTypeInterface = {
      AccountId: this.clinicId,
      UserId: this.userService.getUserIdAndSecret().UserId,
      LinkTypeId: api.switchRelationTypeList.decline,
    };

    this.switchRelationTypeSubscribe = this.userService
      .switchRelationType(request)
      .pipe(timeout(api.requestTimeout))
      .subscribe(
        (response: SwitchRelationTypeResponseInterface) => {
          this.switchRelationTypeSubscribe.unsubscribe();

          if (response.SwitchRelationTypeResult.Success) {
            this.dialogService
              .openStatusDialog(
                'Успешное отклонение!',
                'Вы успешно отклонили работу в клинике!'
              )
              .subscribe(() => {
                this.router.navigate(['/account']);
              });
          } else {
            this.showError();
          }
        },
        () => {
          this.showError();
          this.switchRelationTypeSubscribe.unsubscribe();
        }
      );
  }

  showError() {
    this.dialogService.showStandardError().subscribe(() => {
      this.router.navigate(['/account']);
    });
  }
}
