import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { editorConfig } from '../editor-config';
import {
  AdditionalUseModel,
  AppointmentDraft,
  DraftToDetailModel,
} from '../../../shared/interfaces/appointments/appointments';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { AppointmentService } from '../../../shared/services/appointment.service';

@Component({
  selector: 'app-additional-use-form',
  templateUrl: './additional-use-form.component.html',
  styleUrls: ['./additional-use-form.component.scss'],
})
export class AdditionalUseFormComponent
  implements OnInit, OnChanges, OnDestroy {
  @Input() appointmentDraft: AppointmentDraft;
  @Input() appointmentAfterSaveDraft: AppointmentDraft;
  @Input() updateTypeCreate: boolean;
  @Input()
  set submit(dateSubmit: Date) {
    if (dateSubmit) {
      this.onPrepare();
    }
  }
  @Output() submitted = new EventEmitter();

  form: FormGroup;
  editorConfig = editorConfig;

  constructor(
    private fb: FormBuilder,
    private appointmentService: AppointmentService
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      id: [],
      htmlCode: [],
    });

    if (this.appointmentDraft !== undefined) {
      if (!this.updateTypeCreate) {
        this.updateFormAfterGet();
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.appointmentDraft &&
      this.appointmentDraft !== undefined &&
      this.form
    ) {
      if (!this.updateTypeCreate) {
        this.updateFormAfterGet();
      }
    }

    if (
      changes.appointmentAfterSaveDraft &&
      this.appointmentAfterSaveDraft !== undefined &&
      this.form
    ) {
      this.updateIds();
    }
  }

  updateFormAfterGet() {
    const additionalUse = this.appointmentDraft.AdditionalUses[0];
    if (additionalUse) {
      this.form.patchValue({
        htmlCode: additionalUse.HTMLCode,
        id: additionalUse.Id,
      });
    }
  }

  updateIds() {
    const additionalUse = this.appointmentAfterSaveDraft.AdditionalUses[0];
    if (additionalUse) {
      this.form.patchValue({ id: additionalUse.Id });
    }
  }

  onSubmit() {
    const prepare = this.prepareForm();
    this.submitted.emit(prepare);
  }

  onPrepare() {
    this.appointmentService.prepareAdditionalUseForm = this.prepareForm();
  }

  prepareForm() {
    const controls = this.form.controls;
    const _model: AdditionalUseModel[] = [];
    const newAdditionalUse = new AdditionalUseModel();
    newAdditionalUse.init();
    if (controls.id.value) {
      newAdditionalUse.Id = controls.id.value;
    }
    newAdditionalUse.HTMLCode.Value = controls.htmlCode.value;

    _model.push(newAdditionalUse);

    return _model;
  }

  ngOnDestroy() {}
}
