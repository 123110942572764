import { EventEmitter, Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { UserService } from './user.service';
import { HttpService } from './http.service';
import { Appointment } from '../interfaces/doctor/get-appointment';
import {
  ActivityMedicineModel,
  ActivityToMedicineModel,
  AdditionalUseModel,
  AnamnesisModel,
  AppointmentDraftModel,
  AppointmentDraftRequest,
  AppointmentDraftResponse,
  DiagnosisModel,
  DiagnosticModel,
  DraftToDetailModel,
  GetAppointmentsModel,
  GetHistoryModel,
  HistoryResponse,
  ID,
  PetVaccinationModel,
  SwitchAppointment,
  SwitchAppointmentSchedule,
} from '../interfaces/appointments/appointments';
import { api } from '../../../environments/api';
import { ClinicFullInterface } from '../interfaces/clinic/clinic-full.interface';
import {
  ClinicMedications,
  ClinicServices,
  GetClinicData,
} from '../interfaces/appointments/clinic';
import { map } from 'rxjs/operators';
import { clinicServices } from '../catalogs/clinic.catalogs';
import { SelectInterface } from '../interfaces/select.interface';

@Injectable()
export class AppointmentService {
  currentAppointment: Appointment;
  _preparePetForm: AppointmentDraftModel;
  preparePetFormEmit = new EventEmitter<boolean>();
  _prepareDiagnosisForm: DiagnosisModel[] = [];
  prepareDiagnosisFormEmit = new EventEmitter<boolean>();
  _prepareAnamnesisForm: AnamnesisModel[] = [];
  prepareAnamnesisFormEmit = new EventEmitter<boolean>();
  _prepareDiagnosticForm: DiagnosticModel[] = [];
  prepareDiagnosticFormEmit = new EventEmitter<boolean>();
  _prepareManipulationsForm: DraftToDetailModel[] = [];
  prepareManipulationsFormEmit = new EventEmitter<boolean>();
  _prepareActivityToMedicineForm: ActivityToMedicineModel[] = [];
  prepareActivityToMedicineFormEmit = new EventEmitter<boolean>();
  _prepareActivityMedicineForm: ActivityMedicineModel[] = [];
  prepareActivityMedicineFormEmit = new EventEmitter<boolean>();
  _prepareAdditionalUseForm: AdditionalUseModel[] = [];
  prepareAdditionalUseFormEmit = new EventEmitter<boolean>();
  _preparePetVaccinationForm: PetVaccinationModel[] = [];
  preparePetVaccinationFormEmit = new EventEmitter<boolean>();

  constructor(
    private baseService: BaseService,
    private userService: UserService,
    private httpService: HttpService
  ) {}

  getAppointmentDraft(id) {
    const request: GetAppointmentsModel = new GetAppointmentsModel();
    request.SourceID = api.sourceID;
    request.SourceSecretKey = api.sourceSecretKey;
    request.Code = 'GetAppointmentDraft';
    request.Parameters = {
      ScheduleId: id,
    };

    return this.httpService.postBaseRequestText(request);
  }

  getAppointment(id) {
    return this.httpService.getRequestApi2(`AppointmentDraft/${id}`);
  }

  postAppointmentDraft(model) {
    const UserData = this.userService.getUserIdAndSecret();
    const request = new AppointmentDraftRequest();
    request.SourceID = api.sourceID;
    request.SourceSecretKey = api.sourceSecretKey;
    request.UserData = UserData;
    request.AppointmentDraft = model;

    return this.httpService.postRequestApi2<AppointmentDraftResponse>(
      request,
      'AppointmentDraft'
    );
  }

  saveAppointment(id: ID) {
    return this.httpService.postRequestApi2(null, 'SaveDraft/' + id);
  }

  currentClinic(clinicID) {
    const userIdAndSecret = this.userService.getUserIdAndSecret();

    const request: ClinicFullInterface = {
      SourceID: api.sourceID,
      SourceSecretKey: api.sourceSecretKey,
      UserData: {
        SecretKey: userIdAndSecret.SecretKey,
        UserId: userIdAndSecret.UserId,
      },
      AccountData: {
        AccountId: clinicID,
      },
    };

    return this.httpService.postBaseRequest(
      request,
      api.paths.clinic.currentClinic
    );
  }

  switchAppointment(schedule: SwitchAppointmentSchedule) {
    const userData = this.userService.getUserIdAndSecret();
    const request = new SwitchAppointment();
    request.SourceID = api.sourceID;
    request.SourceSecretKey = api.sourceSecretKey;
    request.UserData = userData;
    request.Schedules = [schedule];

    return this.httpService.postBaseRequest(
      request,
      api.paths.schedule.switchAppointment
    );
  }

  getClinicServices(accountId) {
    const userData = this.userService.getUserIdAndSecret();
    const request = new GetClinicData();
    request.SourceID = api.sourceID;
    request.SourceSecretKey = api.sourceSecretKey;
    request.UserData = userData;
    request.Code = 'GetClinicServices';
    request.Parameters = {
      AccountId: accountId,
    };

    return this.httpService.postBaseRequestText<ClinicServices[]>(request).pipe(
      map((res) => {
        const services: SelectInterface[] = [];
        res.map((x) => {
          let name;
          if (x.BtcClinicServiceId) {
            name = clinicServices.find((d) => d.value === x.BtcClinicServiceId)
              .name;
          } else {
            name = x.CustomText;
          }
          services.push({
            name,
            value: x.Id,
          });
        });
        return services;
      })
    );
  }

  getClinicMedicines(accountId) {
    const userData = this.userService.getUserIdAndSecret();
    const request = new GetClinicData();
    request.SourceID = api.sourceID;
    request.SourceSecretKey = api.sourceSecretKey;
    request.UserData = userData;
    request.Code = 'GetMedicinesByClinic';
    request.Parameters = {
      AccountId: accountId,
    };

    return this.httpService
      .postBaseRequestText<ClinicMedications[]>(request)
      .pipe(
        map((res) => {
          const medication: SelectInterface[] = [];
          res.map((x) => {
            medication.push({
              name: x.Name,
              value: x.Id,
            });
          });
          return medication;
        })
      );
  }

  getHistory(model: GetHistoryModel) {
    return this.httpService.getRequestApi2<HistoryResponse>('History', model);
  }

  set preparePetForm(data: AppointmentDraftModel) {
    this._preparePetForm = data;
    this.preparePetFormEmit.emit(true);
  }

  get preparePetForm(): AppointmentDraftModel {
    return this._preparePetForm;
  }

  set prepareDiagnosisForm(data: DiagnosisModel[]) {
    this._prepareDiagnosisForm = data;
    this.prepareDiagnosisFormEmit.emit(true);
  }

  get prepareDiagnosisForm(): DiagnosisModel[] {
    return this._prepareDiagnosisForm;
  }

  set prepareAnamnesisForm(data: AnamnesisModel[]) {
    this._prepareAnamnesisForm = data;
    this.prepareAnamnesisFormEmit.emit(true);
  }

  get prepareAnamnesisForm(): AnamnesisModel[] {
    return this._prepareAnamnesisForm;
  }

  set prepareDiagnosticForm(data: DiagnosticModel[]) {
    this._prepareDiagnosticForm = data;
    this.prepareDiagnosticFormEmit.emit(true);
  }

  get prepareDiagnosticForm(): DiagnosticModel[] {
    return this._prepareDiagnosticForm;
  }

  set prepareManipulationsForm(data: DraftToDetailModel[]) {
    this._prepareManipulationsForm = data;
    this.prepareManipulationsFormEmit.emit(true);
  }

  get prepareManipulationsForm(): DraftToDetailModel[] {
    return this._prepareManipulationsForm;
  }

  set prepareActivityToMedicineForm(data: ActivityToMedicineModel[]) {
    this._prepareActivityToMedicineForm = data;
    this.prepareActivityToMedicineFormEmit.emit(true);
  }

  get prepareActivityToMedicineForm(): ActivityToMedicineModel[] {
    return this._prepareActivityToMedicineForm;
  }

  set prepareActivityMedicineForm(data: ActivityMedicineModel[]) {
    this._prepareActivityMedicineForm = data;
    this.prepareActivityMedicineFormEmit.emit(true);
  }

  get prepareActivityMedicineForm(): ActivityMedicineModel[] {
    return this._prepareActivityMedicineForm;
  }

  set prepareAdditionalUseForm(data: AdditionalUseModel[]) {
    this._prepareAdditionalUseForm = data;
    this.prepareAdditionalUseFormEmit.emit(true);
  }

  get prepareAdditionalUseForm(): AdditionalUseModel[] {
    return this._prepareAdditionalUseForm;
  }

  set preparePetVaccinationForm(data: PetVaccinationModel[]) {
    this._preparePetVaccinationForm = data;
    this.preparePetVaccinationFormEmit.emit(true);
  }

  get preparePetVaccinationForm(): PetVaccinationModel[] {
    return this._preparePetVaccinationForm;
  }
}
