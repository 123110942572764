import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  AdditionalUseModel,
  AppointmentDraft,
  ID,
  PetVaccinationModel,
  VaccinationTable,
} from '../../../shared/interfaces/appointments/appointments';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { SelectInterface } from '../../../shared/interfaces/select.interface';
import {
  vaccinationCategory,
  vaccinationStatus,
} from '../../../shared/catalogs/appointment.catalog';
import { MatTableDataSource } from '@angular/material';
import { dateToString } from '../../../shared/functions/functions';
import { AppointmentService } from '../../../shared/services/appointment.service';

@Component({
  selector: 'app-vaccination-form',
  templateUrl: './vaccination-form.component.html',
  styleUrls: ['./vaccination-form.component.scss'],
})
export class VaccinationFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() appointmentDraft: AppointmentDraft;
  @Input() appointmentAfterSaveDraft: AppointmentDraft;
  @Input() updateTypeCreate: boolean;
  @Input()
  set submit(dateSubmit: Date) {
    if (dateSubmit) {
      this.onPrepare();
    }
  }
  @Output() submitted = new EventEmitter();

  form: FormGroup;
  displayedColumns: string[] = [
    'date',
    'status',
    'count',
    'name',
    'type',
    'actions',
  ];

  vaccinationPlaceholder: VaccinationTable[] = [
    {
      id: '',
      date: '2021-11-18',
      status: 'AB25D50A-F68A-46A2-AD0E-26E72154A0A3',
      name: 'Нобивак® Puppy DP',
      count: '100 мл',
      category: 'F211EC6A-6C84-4FF9-9E25-A282C5FF3154',
    },
    {
      id: '',
      date: '2021-11-21',
      status: 'AB25D50A-F68A-46A2-AD0E-26E72154A0A3',
      name: 'Нобивак® Puppy DP',
      count: '100 мл',
      category: 'F211EC6A-6C84-4FF9-9E25-A282C5FF3154',
    },
  ];

  tableVaccinations: MatTableDataSource<VaccinationTable>;
  vaccinations: VaccinationTable[] = [];
  selectCategory: SelectInterface[] = vaccinationCategory;
  selectStatus: SelectInterface[] = vaccinationStatus;
  tableCountId = 0;
  isEditVaccination = false;

  constructor(
    private fb: FormBuilder,
    private appointmentService: AppointmentService
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      id: [],
      name: new FormControl(null, [Validators.required]),
      category: new FormControl(null, [Validators.required]),
      count: new FormControl(null, [Validators.required]),
      date: new FormControl(null, [Validators.required]),
      tableId: [],
    });

    if (this.appointmentDraft !== undefined) {
      if (!this.updateTypeCreate) {
        this.updateFormAfterGet();
      }
    }

    this.tableVaccinations = new MatTableDataSource(this.vaccinations);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.appointmentDraft &&
      this.appointmentDraft !== undefined &&
      this.form
    ) {
      if (!this.updateTypeCreate) {
        this.updateFormAfterGet();
      }
    }

    if (
      changes.appointmentAfterSaveDraft &&
      this.appointmentAfterSaveDraft !== undefined &&
      this.form
    ) {
      this.updateIds();
    }
  }

  updateFormAfterGet() {
    this.appointmentDraft.BtcPetVaccinations.map((d) => {
      const vaccination = new VaccinationTable();
      vaccination.id = d.Id;
      vaccination.name = d.BtcName;
      vaccination.count = d.Count;
      vaccination.category = d.CategoryId;
      vaccination.date = d.BtcDate;
      vaccination.status = d.StatusId;
      vaccination.tableId = this.tableCountId;
      this.tableCountId++;

      this.vaccinations.push(vaccination);
    });
  }

  updateIds() {
    this.appointmentAfterSaveDraft.BtcPetVaccinations.map((x, i) => {
      this.vaccinations[i].id = x.Id;
    });
  }

  addVaccination() {
    const values = this.form.value;
    const vaccination = new VaccinationTable();
    vaccination.id = values.id;
    vaccination.name = values.name;
    vaccination.count = values.count;
    vaccination.category = values.category;
    vaccination.date = values.date;
    vaccination.status = this.selectStatus[0].value as ID;
    vaccination.tableId = this.tableCountId;
    this.tableCountId++;

    this.vaccinations.push(vaccination);
    this.updateTable();
    this.form.reset();
    this.onSubmit();
  }

  editVaccination(id) {
    this.isEditVaccination = true;
    const vaccination = this.vaccinations.find((x) => x.tableId === id);
    this.form.patchValue({
      id: vaccination.id,
      name: vaccination.name,
      category: vaccination.category,
      count: vaccination.count,
      date: vaccination.date,
      tableId: vaccination.tableId,
    });
  }

  saveVaccination() {
    const values = this.form.value;
    const vaccination = this.vaccinations.find(
      (x) => x.tableId === values.tableId
    );
    vaccination.id = values.id;
    vaccination.name = values.name;
    vaccination.category = values.category;
    vaccination.count = values.count;
    vaccination.date = values.date;
    this.updateTable();
    this.isEditVaccination = false;
    this.form.reset();
    this.onSubmit();
  }

  updateTable() {
    this.tableVaccinations = new MatTableDataSource(
      [...this.vaccinations].reverse()
    );
  }

  removeVaccination(id) {
    this.vaccinations = this.vaccinations.filter((d) => d.tableId !== id);
    this.updateTable();
    this.onSubmit();
  }

  onSubmit() {
    const prepare = this.prepareForm();
    this.submitted.emit(prepare);
  }

  onPrepare() {
    console.warn(1);
    this.appointmentService.preparePetVaccinationForm = this.prepareForm();
  }

  prepareForm() {
    const _model: PetVaccinationModel[] = [];
    this.vaccinations.map((x) => {
      if (x.tableId !== undefined) {
        const newVaccination = new PetVaccinationModel();
        newVaccination.init();
        const date = dateToString(x.date);
        if (x.id) {
          newVaccination.Id = x.id;
        }
        newVaccination.BtcName.Value = x.name;
        newVaccination.Count.Value = x.count;
        newVaccination.Category.Value = x.category;
        newVaccination.BtcDate.Value = date;
        newVaccination.Status.Value = x.status;
        _model.push(newVaccination);
      }
    });

    return _model;
  }

  ngOnDestroy() {}
}
