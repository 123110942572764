import { SelectInterface } from '../interfaces/select.interface';

export const doctorSpecialization: SelectInterface[] = [
  { value: '2b1ee8a9-ec75-4948-9049-55ebe68f7e97', name: 'Анестезиолог' },
  { value: 'a24704c7-cc5b-4b88-ae47-24e83a72789e', name: 'Герпетолог' },
  { value: 'e587abcb-13d9-4468-8e6b-173f7adec54d', name: 'Гинеколог-акушер' },
  { value: '8de65567-19fd-4c3a-8c57-d9580bf12e27', name: 'Грумер' },
  { value: '46dbd96b-11dd-40d6-8105-c80d74723605', name: 'Дерматолог' },
  { value: 'bb4185ac-7360-447e-9ced-10cb4ccc1184', name: 'Кардиолог' },
  { value: '5fb8978f-ec08-41f7-8e4a-93b23fb9082a', name: 'Лаборатория' },
  { value: '16e5bd5d-d6bf-443e-96ba-7f30f89caf92', name: 'Невролог' },
  { value: 'f4d36a52-92de-4003-a48b-1d8c924cd97f', name: 'Онколог' },
  { value: '2d85c5d9-12e5-410a-9b3c-7b69f75b0ae8', name: 'Офтальмолог' },
  {
    value: '30ff1029-1373-4099-929f-35d80566e1e2',
    name: 'Специалист по грызунам и экзотическим животным',
  },
  { value: '98e9ec43-4045-4229-bb4f-950c30ee1e80', name: 'Стоматолог' },
  { value: '005c4a19-2be0-4a8c-8ac0-d6ce79ea4261', name: 'Терапевт' },
  { value: '331e307b-ae36-4a1b-b020-fcb5dd246008', name: 'Хирург' },
  { value: '113756c1-9f79-40b1-8b38-aaf133d82529', name: 'Эндокринолог' },
  { value: '5725ef01-8772-414a-8207-7e1a1fc984a9', name: 'Эндоскопист' },
];

export const jobChangeReason: SelectInterface[] = [
  { value: '38296fb8-57e6-df11-971b-001d60e938c6', name: 'Better job offer' },
  {
    value: '51580fbf-57e6-df11-971b-001d60e938c6',
    name: 'Career growth/promotion',
  },
  { value: '3adf39c5-57e6-df11-971b-001d60e938c6', name: 'Personal reasons' },
  {
    value: '87f935cf-57e6-df11-971b-001d60e938c6',
    name: 'Good career opportunity',
  },
];

export const job: SelectInterface[] = [
  { value: '11d68189-ced6-df11-9b2a-001d60e938c6', name: 'Developer' },
  { value: '955472f0-56e6-df11-971b-001d60e938c6', name: 'Sales manager' },
  { value: '33f48df9-56e6-df11-971b-001d60e938c6', name: 'Marketing manager' },
  { value: '34f48df9-56e6-df11-971b-001d60e938c6', name: 'CEO' },
  { value: 'ca49a603-57e6-df11-971b-001d60e938c6', name: 'Department manager' },
  { value: 'cb49a603-57e6-df11-971b-001d60e938c6', name: 'Specialist' },
];

export const department: SelectInterface[] = [
  { value: '66ffa487-b4da-df11-9b2a-001d60e938c6', name: 'Administration' },
  { value: '4691a98e-b4da-df11-9b2a-001d60e938c6', name: 'Logistics' },
  { value: '4791a98e-b4da-df11-9b2a-001d60e938c6', name: 'Marketing' },
  { value: '769964a0-b4da-df11-9b2a-001d60e938c6', name: 'Production' },
  { value: '9b90c192-60e6-df11-971b-001d60e938c6', name: 'Development' },
  { value: '2076c4b6-7fe6-df11-971b-001d60e938c6', name: 'Sales' },
];

export const decisionRole: SelectInterface[] = [
  { value: 'ed46bb3a-57e6-df11-971b-001d60e938c6', name: 'Decision maker' },
  { value: 'd1ab0e4e-57e6-df11-971b-001d60e938c6', name: 'Contact person' },
  { value: 'd2ab0e4e-57e6-df11-971b-001d60e938c6', name: 'Influencer' },
  { value: '91e32c57-57e6-df11-971b-001d60e938c6', name: 'Performer' },
  { value: 'f71ee81d-0cac-4e41-b7dc-9d4bd2e08491', name: 'Manager' },
];

export const postGraduateEducationType: SelectInterface[] = [
  { value: '25e3df7d-30c3-42c5-a133-ecbb4ad56848', name: 'Конференция' },
  { value: 'acbc0296-3a43-4599-961f-c4d5709b38a7', name: 'Симпозиум' },
  { value: '566c83e2-80bf-4701-9833-4fadb4ad4a8f', name: 'Вебинар' },
  { value: 'f4c3936b-52f0-4499-9fa7-76f8bd7a30e7', name: 'Круглый стол' },
  { value: 'ae6b4346-ad2e-47f5-a144-b6a5187ebefb', name: 'Конгресс' },
];

export const diagnoseList: SelectInterface[] = [
  { value: 'A.01	Герпесвирусная инфекция', name: 'A.01	Герпесвирусная инфекция' },
  { value: 'A.02	Калицивирусная инфекция', name: 'A.02	Калицивирусная инфекция' },
  { value: 'A.03	Хламидиоз', name: 'A.03	Хламидиоз' },
  { value: 'A.04	Бордетеллез', name: 'A.04	Бордетеллез' },
  { value: 'A.05	Панлейкопения', name: 'A.05	Панлейкопения' },
  { value: 'A.06	Микоплазмоз', name: 'A.06	Микоплазмоз' },
  { value: 'A.07	Синдром атаксии кошек', name: 'A.07	Синдром атаксии кошек' },
  { value: 'A.08	Вирусный перетонит', name: 'A.08	Вирусный перетонит' },
  {
    value: 'A.09	Вирусный гемморагический энтерит собак',
    name: 'A.09	Вирусный гемморагический энтерит собак',
  },
  { value: 'A.10	Коронавирусная инфекция', name: 'A.10	Коронавирусная инфекция' },
  {
    value: 'A.11	Сухой инфекционный перетонит кошек',
    name: 'A.11	Сухой инфекционный перетонит кошек',
  },
  {
    value: 'A.12	Выпотный инфекционный перетонит кошек',
    name: 'A.12	Выпотный инфекционный перетонит кошек',
  },
  {
    value: 'A.13	Вирусная лекйкемия кошек',
    name: 'A.13	Вирусная лекйкемия кошек',
  },
  {
    value: 'A.14	Вирусный иммунодефецит кошек',
    name: 'A.14	Вирусный иммунодефецит кошек',
  },
  {
    value: 'A.15	Поксвирусная инфекция кошек',
    name: 'A.15	Поксвирусная инфекция кошек',
  },
  {
    value: 'A.16	Губчатая энцефалопатия кошек',
    name: 'A.16	Губчатая энцефалопатия кошек',
  },
  { value: 'A.17	Бациллез', name: 'A.17	Бациллез' },
  {
    value: 'A.18	Ротавирусная инфекция собак',
    name: 'A.18	Ротавирусная инфекция собак',
  },
  {
    value: 'A.19	Инфекционный гепатит собак',
    name: 'A.19	Инфекционный гепатит собак',
  },
  { value: 'A.20	Чума плотоядных', name: 'A.20	Чума плотоядных' },
  {
    value: 'A.21	Инфекционный трахеобронхит',
    name: 'A.21	Инфекционный трахеобронхит',
  },
  { value: 'A.22	Парагрипп собак', name: 'A.22	Парагрипп собак' },
  {
    value: 'A.23	Аденовирусная инфекция собак',
    name: 'A.23	Аденовирусная инфекция собак',
  },
  {
    value: 'A.24	Реовирусная инфекция собак',
    name: 'A.24	Реовирусная инфекция собак',
  },
  { value: 'A.25	Бешенство', name: 'A.25	Бешенство' },
  {
    value: 'A.26	Вирусный паппиломатоз собак',
    name: 'A.26	Вирусный паппиломатоз собак',
  },
  { value: 'A.27	Лептоспироз', name: 'A.27	Лептоспироз' },
  { value: 'A.28	Бруцеллез', name: 'A.28	Бруцеллез' },
  { value: 'A.29	Болезнь Ауески', name: 'A.29	Болезнь Ауески' },
  { value: 'A.30	Хантавирусная инфекция', name: 'A.30	Хантавирусная инфекция' },
  {
    value: 'A.31	Ротавирусная инфекция кошек',
    name: 'A.31	Ротавирусная инфекция кошек',
  },
  {
    value: 'A.32	Астрофирусная инфекция кошек',
    name: 'A.32	Астрофирусная инфекция кошек',
  },
  {
    value: 'A.33	Торовирусная инфекция кошек',
    name: 'A.33	Торовирусная инфекция кошек',
  },
  {
    value: 'A.34	Синцитийобразующая вирусная инфекция кошек',
    name: 'A.34	Синцитийобразующая вирусная инфекция кошек',
  },
  {
    value: 'A.35	Парапоксивирусная инфекция кошек',
    name: 'A.35	Парапоксивирусная инфекция кошек',
  },
  {
    value: 'A.36	"Шатающаяся болезнь" кошек',
    name: 'A.36	"Шатающаяся болезнь" кошек',
  },
  {
    value: 'A.37	Ацидофильно-клеточный гепатит собак',
    name: 'A.37	Ацидофильно-клеточный гепатит собак',
  },
  {
    value: 'A.38	Калицивирусная инфекция собак',
    name: 'A.38	Калицивирусная инфекция собак',
  },
  {
    value: 'A.39	Ретровирусная инфекция собак',
    name: 'A.39	Ретровирусная инфекция собак',
  },
  {
    value: 'A.40	Парамиксовирусная инфекция кошек',
    name: 'A.40	Парамиксовирусная инфекция кошек',
  },
  { value: 'A.41	Грипп кошек', name: 'A.41	Грипп кошек' },
  { value: 'A.42	Тогавирусная инфекция', name: 'A.42	Тогавирусная инфекция' },
  { value: 'A.43	Актиномикоз', name: 'A.43	Актиномикоз' },
  { value: 'A.44	Нокардиоз', name: 'A.44	Нокардиоз' },
  { value: 'A.45	Туберкулез', name: 'A.45	Туберкулез' },
  { value: 'A.46	Лепра кошек', name: 'A.46	Лепра кошек' },
  {
    value: 'A.47	Атипичная микобактериальная инфекция',
    name: 'A.47	Атипичная микобактериальная инфекция',
  },
  {
    value: 'A.48	Стрептококковая инфекция',
    name: 'A.48	Стрептококковая инфекция',
  },
  { value: 'A.49	Болезнь Тиззера', name: 'A.49	Болезнь Тиззера' },
  { value: 'A.50	Колбациллез', name: 'A.50	Колбациллез' },
  { value: 'A.51	Сальмонеллез', name: 'A.51	Сальмонеллез' },
  { value: 'A.52	Ботулизм', name: 'A.52	Ботулизм' },
  { value: 'A.53	Столбняк', name: 'A.53	Столбняк' },
  { value: 'A.54	Болезнь Лайма', name: 'A.54	Болезнь Лайма' },
  { value: 'A.55	Лихорадка Ку', name: 'A.55	Лихорадка Ку' },
  { value: 'A.56	Гемобартонеллез', name: 'A.56	Гемобартонеллез' },
  {
    value: 'A.57	Болезнь кошачей царапины',
    name: 'A.57	Болезнь кошачей царапины',
  },
  { value: 'A.58	Бациллярный ангиматоз', name: 'A.58	Бациллярный ангиматоз' },
  {
    value: 'A.59	Трематодная лихорадка Элокомина',
    name: 'A.59	Трематодная лихорадка Элокомина',
  },
  { value: 'A.60	Эрлихиоз', name: 'A.60	Эрлихиоз' },
  {
    value: 'A.61	Инфекционная цикличсекая тромбоцитопения',
    name: 'A.61	Инфекционная цикличсекая тромбоцитопения',
  },
  {
    value: 'A.62	Пятнистая лихорадка скалитсых гор',
    name: 'A.62	Пятнистая лихорадка скалитсых гор',
  },
  { value: 'A.63	Листериоз', name: 'A.63	Листериоз' },
  { value: 'A.64	Сибирская язва', name: 'A.64	Сибирская язва' },
  {
    value: 'A.65	Стафилакокковая инфекция',
    name: 'A.65	Стафилакокковая инфекция',
  },
  { value: 'A.66	Иерсиниоз', name: 'A.66	Иерсиниоз' },
  { value: 'A.67	Чума', name: 'A.67	Чума' },
  { value: 'A.68	Хелибактериоз', name: 'A.68	Хелибактериоз' },
  { value: 'A.69	Дерматофитоз', name: 'A.69	Дерматофитоз' },
  { value: 'A.70	Аспергиллез', name: 'A.70	Аспергиллез' },
  { value: 'A.71	Кандидоз', name: 'A.71	Кандидоз' },
  { value: 'A.72	Бластомикоз', name: 'A.72	Бластомикоз' },
  { value: 'A.73	Гистоплазмоз', name: 'A.73	Гистоплазмоз' },
  { value: 'A.74	Криптококкоз', name: 'A.74	Криптококкоз' },
  { value: 'A.75	Кокцидиомикоз', name: 'A.75	Кокцидиомикоз' },
  { value: 'A.76	Споротрихоз', name: 'A.76	Споротрихоз' },
  { value: 'A.77	Риноспоридиоз', name: 'A.77	Риноспоридиоз' },
  { value: 'A.78	Трихоспороз', name: 'A.78	Трихоспороз' },
  { value: 'A.79	Прототекоз', name: 'A.79	Прототекоз' },
  { value: 'A.80	Токсоплазмоз', name: 'A.80	Токсоплазмоз' },
  { value: 'A.81	Лямблиоз', name: 'A.81	Лямблиоз' },
  { value: 'A.82	Кокцидиоз', name: 'A.82	Кокцидиоз' },
  { value: 'A.83	Криптоспоридиоз', name: 'A.83	Криптоспоридиоз' },
  { value: 'A.84	Неоспороз', name: 'A.84	Неоспороз' },
  { value: 'A.85	Лейшманиоз', name: 'A.85	Лейшманиоз' },
  { value: 'A.86	Гепатозооноз', name: 'A.86	Гепатозооноз' },
  { value: 'A.87	Пироплазмоз', name: 'A.87	Пироплазмоз' },
  { value: 'A.88	Болезнь Чагаса', name: 'A.88	Болезнь Чагаса' },
  { value: 'A.89	Анаплазмоз', name: 'A.89	Анаплазмоз' },
  {
    value: 'A.90	Вирусный ринотрахеит кошек',
    name: 'A.90	Вирусный ринотрахеит кошек',
  },
  { value: 'A.91	Уреаплазмоз', name: 'A.91	Уреаплазмоз' },
  {
    value: 'A.92	Цитомегаловирусная инфекция',
    name: 'A.92	Цитомегаловирусная инфекция',
  },
  {
    value: 'A.93	Неуточненная вирусная инфекция',
    name: 'A.93	Неуточненная вирусная инфекция',
  },
  {
    value: 'A.94	Неуточненная бактериальная инфекция',
    name: 'A.94	Неуточненная бактериальная инфекция',
  },
  {
    value: 'A.95	Неуточненная грибковая инфекция',
    name: 'A.95	Неуточненная грибковая инфекция',
  },
  {
    value: 'A.96	Неуточненное прионое заболевание',
    name: 'A.96	Неуточненное прионое заболевание',
  },
  {
    value: 'A.97	Инфекционное заболевание неустановленной этиологии',
    name: 'A.97	Инфекционное заболевание неустановленной этиологии',
  },
];
