import { Component, OnInit } from '@angular/core';
import { UserService } from '../../shared/services/user.service';
import { api } from '../../../environments/api';
import { Subscription } from 'rxjs';
import { NeedConfirmResponseInterface } from '../../shared/interfaces/need-confirm-response.interface';
import { SendConfirmResponseInterface } from '../../shared/interfaces/send-confirm-response.interface';
import { DoctorFullInterface } from '../../shared/interfaces/doctor/doctor-full.interface';
import { DoctorInterface } from '../../shared/interfaces/doctor/doctor.interface';
import { SelectInterface } from '../../shared/interfaces/select.interface';
import { doctorSpecialization } from '../../shared/catalogs/doctor.catalog';
import { PublicationsInMediaInterface } from '../../shared/interfaces/doctor/publications-in-media.interface';
import { ScientificPublicatInterface } from '../../shared/interfaces/doctor/scientific-publicat.interface';
import { MatDialog } from '@angular/material';
import { DialogInterface } from '../../shared/interfaces/dialog.interface';
import { AccountDialog } from '../account.component';

@Component({
  selector: 'app-account-info',
  templateUrl: './account-info.component.html',
  styleUrls: ['./account-info.component.scss'],
})
export class AccountInfoComponent implements OnInit {
  userCurrentData: DoctorInterface;
  userFullData: DoctorFullInterface;
  showConfirmationEmail: Boolean = false;
  timeout: Boolean = true;
  errorMessage: String = api.mainErrorMessage;
  preloaderVisibility: Boolean = true;
  phone: string;
  beginningOfExperience: string;
  specialization: string[] = [];
  catalogSpecializations: SelectInterface[] = doctorSpecialization;
  publicationsInMedia: PublicationsInMediaInterface[];
  scientificPublicat: ScientificPublicatInterface[];

  sendCodeSubscribe: Subscription;

  constructor(private userService: UserService, private dialog: MatDialog) {}

  ngOnInit() {
    console.log(this.userService.getUserData().UserData);

    const userFullDataLink = this.userService.getUserData();
    const userDataLink = this.userService.getUserData().UserData;

    let fullData: DoctorFullInterface = {
      UserData: {},
    };

    let data: DoctorInterface = {};

    for (let key in userDataLink) {
      data[key] = userDataLink[key];

      if (key == 'Phone') {
        data[key] ? (data[key] = data[key].slice(1)) : null;
      } else if (key != 'Middlename') {
        !data[key] ? (data[key] = 'Не указано') : null;
      }
    }

    for (let key in userFullDataLink) {
      fullData[key] = userFullDataLink[key];
    }

    this.userCurrentData = data;
    this.userFullData = fullData;

    let phone = this.userCurrentData.Phone;

    phone ? (this.phone = phone.slice(1)) : (this.phone = null);

    let beginningOfExperience = this.userCurrentData.BeginningOfExperience;

    beginningOfExperience == '0001-01-01'
      ? (this.beginningOfExperience = null)
      : (this.beginningOfExperience = beginningOfExperience);

    this.publicationsInMedia = this.userFullData.PublicationsInMedia;

    for (let key in this.publicationsInMedia) {
      if (
        !!this.publicationsInMedia[key].LinkToArticle.indexOf('http://') &&
        !!this.publicationsInMedia[key].LinkToArticle.indexOf('https://')
      ) {
        this.publicationsInMedia[key].LinkToArticle =
          'http://' + this.publicationsInMedia[key].LinkToArticle;
      }
    }

    this.scientificPublicat = this.userFullData.ScientificPublicat;

    for (let key in this.scientificPublicat) {
      if (
        !!this.scientificPublicat[key].LinkToPublication.indexOf('http://') &&
        !!this.scientificPublicat[key].LinkToPublication.indexOf('https://')
      ) {
        this.scientificPublicat[key].LinkToPublication =
          'http://' + this.scientificPublicat[key].LinkToPublication;
      }
    }

    const specialization = this.userFullData.ContactSpecializat;

    for (let item of specialization) {
      this.catalogSpecializations.forEach((el) => {
        if (el.value === item.Specialization) {
          this.specialization.push(el.name);
        }
      });
    }

    this.userService
      .needConfirmation('Email')
      .subscribe((response: NeedConfirmResponseInterface) => {
        response.NeedCommunicationConfirmationResult.Success
          ? (this.showConfirmationEmail = true)
          : (this.showConfirmationEmail = false);
      });
  }

  sendConfirmationRequest() {
    this.preloaderVisibility = true;
    this.timeout = true;

    this.userService
      .sendConfirmationCode('Email')
      .subscribe((response: SendConfirmResponseInterface) => {
        this.timeout = false;
        this.preloaderVisibility = false;

        if (response.SendConfirmationCodeResult.Success) {
          this.openDialog(
            'Письмо отправлено!',
            'Вам на почту отправленна ссылка для подтверждения регистрации <br> Пожалуйста, перейдите по ней, чтобы пользоваться всеми возможностями нашего сервиса. Спасибо!'
          );
        } else {
          this.openDialog(
            'Ошибка отправки письма!',
            'Во время отправки письма для подтверждения почты возникла ошибка <br> Пожалуйста, попробуйте позже или обратитесь в службу поддержки.',
            true
          );
        }
      });

    setTimeout(() => {
      if (this.timeout) {
        this.preloaderVisibility = false;
        this.openDialog(
          'Ошибка отправки письма!',
          'Во время отправки письма для подтверждения почты возникла ошибка <br> Пожалуйста, попробуйте позже или обратитесь в службу поддержки.',
          true
        );
        this.sendCodeSubscribe.unsubscribe();
      }
    }, 30000);
  }

  openDialog(
    title: string,
    message: string,
    error: boolean = false,
    back: string = null
  ) {
    const data: DialogInterface = {
      title: title,
      message: message,
      error: error,
      back: back,
    };

    const dialogRef = this.dialog.open(AccountDialog, {
      data: data,
    });
  }
}
