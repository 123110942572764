import { FileInterface } from '../file.interface';
import { UserIdAndSecretInterface } from '../user/user-id-and-secret.interface';
import { ResponseInterface } from '../response.interface';

export declare type ID = number | string;

export class GetAppointmentsModel {
  SourceID: string;
  SourceSecretKey: string;
  Code: string;
  Parameters: {
    ScheduleId: string;
  };
}

export enum ValueTypeEnum {
  Lookup = 'lookup',
}

export class ValueModel<T> {
  Value: T;
  Type?: ValueTypeEnum;
}

export enum AppointmentFormSection {
  Pet,
  Diagnosis,
  Anamnesis,
  Diagnostic,
  Manipulations,
  ActivityToMedicine,
  ActivityMedicine,
  AdditionalUse,
  PetVaccination,
}

export enum AppointmentAnamnesisType {
  LiveHistory = 'b4888041-2ffb-4985-b06d-25f87acddf4c',
  MedicalHistory = '1f86011f-5819-4dd9-a47e-d674e7f9fc54',
}

export class AppointmentDraftRequest {
  SourceSecretKey: string;
  SourceID: ID;
  UserData: UserIdAndSecretInterface;
  AppointmentDraft: AppointmentDraftModel;
}

export interface AppointmentDraftResponse {
  AppointmentDraft: AppointmentDraft;
  UserName: string;
  UserPhone: string;
  PetName: string;
  PetTypeId: ID;
  Success: boolean;
  Message: string;
  ErrorCode: number;
  Warnings: any;
}

export class AppointmentDraftModel {
  Id?: ID;
  AccountId: ID;
  ActivitySchedulerAccount: ValueModel<ID>;
  PetId: ID;
  UserName: string;
  UserPhone: string;
  Complaint: ValueModel<string>;
  StartDate: ValueModel<string>;
  Breed?: ValueModel<ID>;
  Sex?: ValueModel<ID>;
  Age?: ValueModel<ID>;
  Weight?: ValueModel<number>;
  TemperatureC?: ValueModel<number>;
  TemperatureF?: ValueModel<number>;
  Birthday?: ValueModel<string>;
  LengthCoat?: ValueModel<ID>;
  IsNeutered?: ValueModel<boolean>;
  Color?: ValueModel<string>;
  Height?: ValueModel<number>;
  HealthFeature?: HealthFeatureModel[];
  Anamnesis: AnamnesisModel[];
  Diagnosis: DiagnosisModel[];
  Diagnostic: DiagnosticModel[];
  DraftToDetail: DraftToDetailModel[];
  ActivityToMedicine: ActivityToMedicineModel[];
  ActivityMedicine: ActivityMedicineModel[];
  AdditionalUse: AdditionalUseModel[];
  BtcPetVaccination: PetVaccinationModel[];
  Account: ValueModel<ID>;
  Specialist: ValueModel<ID>;

  init() {
    this.ActivitySchedulerAccount = new ValueModel();
    this.ActivitySchedulerAccount.Value = '';
    this.ActivitySchedulerAccount.Type = ValueTypeEnum.Lookup;
    this.PetId = '';
    this.UserName = '';
    this.UserPhone = '';
    this.Complaint = new ValueModel();
    this.StartDate = new ValueModel();
    this.Breed = new ValueModel<ID>();
    this.Breed.Type = ValueTypeEnum.Lookup;
    this.Sex = new ValueModel<ID>();
    this.Sex.Type = ValueTypeEnum.Lookup;
    this.Age = new ValueModel<ID>();
    this.Age.Type = ValueTypeEnum.Lookup;
    this.Weight = new ValueModel();
    this.TemperatureC = new ValueModel();
    this.TemperatureF = new ValueModel();
    this.Birthday = new ValueModel();
    this.LengthCoat = new ValueModel<ID>();
    this.LengthCoat.Type = ValueTypeEnum.Lookup;
    this.IsNeutered = new ValueModel();
    this.Color = new ValueModel();
    this.Height = new ValueModel();
    this.Account = new ValueModel();
    this.Account.Type = ValueTypeEnum.Lookup;
    this.Specialist = new ValueModel();
    this.Specialist.Type = ValueTypeEnum.Lookup;
  }
}

export class HealthFeatureModel {
  Id?: ID;
  DermatosisHairLoss: ValueModel<boolean>;
  HeartFailure: ValueModel<boolean>;
  KidneyDisease: ValueModel<boolean>;
  SensitiveDigestion: ValueModel<boolean>;
  DiseasesJoint: ValueModel<boolean>;
  Allergy: ValueModel<boolean>;
  Obesity: ValueModel<boolean>;
  LiverDisease: ValueModel<boolean>;
  UrolithiasisDisease: ValueModel<boolean>;
  Recovery: ValueModel<boolean>;
  Diabetes: ValueModel<boolean>;

  init() {
    this.DermatosisHairLoss = new ValueModel();
    this.HeartFailure = new ValueModel();
    this.KidneyDisease = new ValueModel();
    this.SensitiveDigestion = new ValueModel();
    this.DiseasesJoint = new ValueModel();
    this.Allergy = new ValueModel();
    this.Obesity = new ValueModel();
    this.LiverDisease = new ValueModel();
    this.UrolithiasisDisease = new ValueModel();
    this.Recovery = new ValueModel();
    this.Diabetes = new ValueModel();
  }
}

export class AnamnesisModel {
  Id?: ID;
  Description: ValueModel<string>;
  AnamnesisType: ValueModel<AppointmentAnamnesisType>;
  AnamnesisCategory: AnamnesisCategoryModel[];

  init() {
    this.Description = new ValueModel();
    this.AnamnesisType = new ValueModel();
    this.AnamnesisType.Type = ValueTypeEnum.Lookup;
  }
}

export class AnamnesisCategoryModel {
  Id?: ID;
  Name: ValueModel<ID>;
  AnamnesisQuestion: AnamnesisQuestionModel[];

  init() {
    this.Name = new ValueModel();
  }
}

export class AnamnesisQuestionModel {
  Id?: ID;
  Question: ValueModel<string>;
  Answer: ValueModel<string>;

  init() {
    this.Question = new ValueModel();
    this.Answer = new ValueModel();
  }
}

export class DiagnosisModel {
  Id?: ID;
  Name: ValueModel<string>;
  Code?: ValueModel<string>;
  DiagnosisType: ValueModel<ID>;
  Date: ValueModel<string>;

  init() {
    this.Name = new ValueModel();
    this.DiagnosisType = new ValueModel();
    this.DiagnosisType.Type = ValueTypeEnum.Lookup;
    this.Date = new ValueModel();
  }
}

export class DiagnosticModel {
  Id?: ID;
  HTMLCode: ValueModel<string>;
  DiagnosticFile: DiagnosticFileModel[];

  init() {
    this.HTMLCode = new ValueModel();
  }
}

export class DiagnosticFileModel {
  Id?: ID;
  Name: ValueModel<string>;
  CreatedOn: ValueModel<string>;
  Base64: ValueModel<string>;

  init() {
    this.Name = new ValueModel();
    this.CreatedOn = new ValueModel();
    this.Base64 = new ValueModel();
  }
}

export class DraftToDetailModel {
  Id?: ID;
  AccClinicService: ValueModel<ID>;

  init() {
    this.AccClinicService = new ValueModel();
    this.AccClinicService.Type = ValueTypeEnum.Lookup;
  }
}

export class ActivityToMedicineModel {
  Id?: ID;
  ClinicMedicine: ValueModel<ID>;
  Count: ValueModel<number>;

  init() {
    this.ClinicMedicine = new ValueModel();
    this.ClinicMedicine.Type = ValueTypeEnum.Lookup;
    this.Count = new ValueModel();
  }
}

export class ActivityMedicineModel {
  Id?: ID;
  Name: ValueModel<string>;
  Count: ValueModel<string>;
  HowMany: ValueModel<string>;
  Frequency: ValueModel<ID>;
  Description: ValueModel<string>;

  init() {
    this.Name = new ValueModel();
    this.Count = new ValueModel();
    this.HowMany = new ValueModel();
    this.Frequency = new ValueModel();
    this.Frequency.Type = ValueTypeEnum.Lookup;
    this.Description = new ValueModel();
  }
}

export class AdditionalUseModel {
  Id?: ID;
  HTMLCode: ValueModel<string>;

  init() {
    this.HTMLCode = new ValueModel();
  }
}

export class PetVaccinationModel {
  Id?: ID;
  BtcName: ValueModel<string>;
  Count: ValueModel<string>;
  Category: ValueModel<ID>;
  BtcDate: ValueModel<string>;
  Status: ValueModel<ID>;

  init() {
    this.BtcName = new ValueModel();
    this.BtcDate = new ValueModel();
    this.Count = new ValueModel();
    this.Category = new ValueModel();
    this.Category.Type = ValueTypeEnum.Lookup;
    this.Status = new ValueModel();
    this.Status.Type = ValueTypeEnum.Lookup;
  }
}

export class VaccinationTable {
  id?: ID;
  name: string;
  count: string;
  category: ID;
  date: string;
  status: ID;
  tableId?: ID;
}

export interface AppointmentDraft {
  Id?: ID;
  StartDate?: string;
  Complaint?: string;
  BreedId?: ID;
  SexId?: ID;
  Birthday?: string;
  LengthCoatId?: ID;
  Color?: string;
  IsNeutered?: boolean;
  Weight?: number;
  Height?: number;
  TemperatureC?: number;
  TemperatureF?: number;
  ActivitySchedulerAccountId?: ID;
  PetId?: ID;
  AgeId?: ID;
  Pet?: AppointmentPet;
  AccountId?: ID;
  SpecialistId?: ID;
  HealthFeatures?: HealthFeatures[];
  Anamnesis?: Anamnesis[];
  Diagnosis?: Diagnosis[];
  Diagnostics?: Diagnostics[];
  DraftToDetails?: DraftToDetails[];
  ActivityToMedicines?: ActivityToMedicines[];
  ActivityMedicines?: ActivityMedicines[];
  AdditionalUses?: AdditionalUses[];
  BtcPetVaccinations?: BtcPetVaccinations[];
  CreatedOn?: string;
  ModifiedOn?: string;
}

export interface HealthFeatures {
  Id?: ID;
  DermatosisHairLoss: boolean;
  HeartFailure: boolean;
  KidneyDisease: boolean;
  SensitiveDigestion: boolean;
  DiseasesJoint: boolean;
  Allergy: boolean;
  Obesity: boolean;
  LiverDisease: boolean;
  UrolithiasisDisease: boolean;
  Recovery: boolean;
  Diabetes: boolean;
}

export interface Anamnesis {
  Id?: ID;
  Description: string;
  AnamnesisTypeId: AppointmentAnamnesisType;
  AppointmentDraftId: ID;
  AnamnesisCategories: AnamnesisCategories[];
  CreatedOn?: string;
  ModifiedOn?: string;
}

export class AnamnesisCategories {
  Id?: ID;
  Description?: string;
  Name: string;
  AnamnesisId?: ID;
  AnamnesisQuestions: AnamnesisQuestions[];
  CreatedOn?: string;
  ModifiedOn?: string;
}

export class AnamnesisQuestions {
  Id?: ID;
  Question: string;
  Answer: string;
  AnamnesisCategoryId: ID;
  CreatedOn: string;
  ModifiedOn: string;
}

export class Diagnosis {
  Id?: ID;
  Name: string;
  Code: string;
  DiagnosisTypeId: ID;
  AppointmentDraftId: ID;
  Date: string;
  ActivitySchedulerAccountId: ID;
  CreatedOn?: string;
  ModifiedOn?: string;
}

export interface Diagnostics {
  Id?: ID;
  HTMLCode: string;
  AppointmentDraftId: ID;
  ActivitySchedulerAccountId: ID;
  DiagnosticFiles: DiagnosticFile[];
  CreatedOn?: string;
  ModifiedOn?: string;
}

export interface DiagnosticFile {
  Base64: string;
  CreatedOn: string;
  DiagnosticId: ID;
  Id: ID;
  ModifiedOn: string;
  Name: string;
}

export class DraftToDetails {
  Id?: ID;
  AppointmentDraftId: ID;
  AccClinicServiceId: ID;
  CreatedOn?: string;
  ModifiedOn?: string;
}

export class ActivityToMedicines {
  Id?: ID;
  ActivitySchedulerAccountId: ID;
  ClinicMedicineId: ID;
  AppointmentDraftId: ID;
  Count: number;
  CreatedOn?: string;
  ModifiedOn?: string;
}

export class ActivityMedicines {
  Id?: ID;
  Name: string;
  Count: string;
  HowMany: string;
  FrequencyId: ID;
  Description: string;
}

export interface AdditionalUses {
  Id?: ID;
  HTMLCode: string;
  AppointmentDraftId: ID;
  CreatedOn?: string;
  ModifiedOn?: string;
}

export interface BtcPetVaccinations {
  Id?: ID;
  BtcPetId: ID;
  BtcName: string;
  BtcDate: string;
  TypeId: ID;
  Vaccincation: string;
  Count: string;
  ActivitySchedulerAccountId: ID;
  CategoryId: ID;
  StatusId: ID;
  AppointmentDraftId: ID;
  CreatedOn?: string;
  ModifiedOn?: string;
}

export interface AppointmentPet {
  Id: ID;
  BtcTypeId: ID;
  BtcName: string;
  SiteUserId: ID;
  SiteUser: AppointmentSiteUser;
  BtcBreedId: ID;
  BtcSexId: ID;
  BtcBirthday: string;
  LengthCoatId: ID;
  BtcColor: string;
  TemperatureC: number;
  TemperatureF: number;
  DermatosisHairLoss: boolean;
  HeartFailure: boolean;
  KidneyDisease: boolean;
  SensitiveDigestion: boolean;
  DiseasesJoint: boolean;
  Allergy: boolean;
  Obesity: boolean;
  LiverDisease: boolean;
  UrolithiasisDisease: boolean;
  Recovery: boolean;
  Diabetes: boolean;
  AgePetId: ID;
  IsNeutered: boolean;
  CreatedOn?: string;
  ModifiedOn?: string;
  PetGrowth?: PetGrowthWeight[];
  PetWeight?: PetGrowthWeight[];
}

export interface AppointmentSiteUser {
  Id: ID;
  AboutSpecialist: string;
  SmrName: string;
  SmrSiteUserTypeId: ID;
  SmrContactId: ID;
  SmrCity: string;
  SmrAge: number;
  SmrGender: string;
  SmrPhone: string;
  SmrEmail: string;
  SmrAddress: string;
  SmrCurrentAge: number;
  CreatedOn?: string;
  ModifiedOn?: string;
}

export interface PetGrowthWeight {
  BtcDate: string;
  BtcPetId: ID;
  BtcValue: number;
  CreatedOn: string;
  Id: ID;
  ModifiedOn: string;
}

export enum PetTypesEnum {
  Cat = '6e40453e-010e-452e-b7ab-e0bb8a9fe12b',
  Dog = '5e802ebd-39bb-4a50-9126-a7d35ff756f9',
}

export enum AppointmentStatusEnum {
  NotStarted = '384d4b84-58e6-df11-971b-001d60e938c6',
  Progress = '394d4b84-58e6-df11-971b-001d60e938c6',
  Completed = '4bdbb88f-58e6-df11-971b-001d60e938c6',
  Canceled = '201cfba8-58e6-df11-971b-001d60e938c6',
  New = '6c062529-990b-4c0d-8f42-99cf0131d1ea',
  Confirm = '09b3369f-8b51-48d4-ba6c-82b1d2a4de90',
  NotConfirm = '01a7c232-a23c-459a-b2a9-190bac41b973',
  MaybeeCanceled = '8e53c054-72fe-4e4a-b3fe-f57571e91990',
}

export interface SwitchAppointmentResponse {
  SwitchAppointmentResult: ResponseInterface;
}

export class SwitchAppointmentSchedule {
  Id: string;
  StatusId: string;
}

export class SwitchAppointment {
  SourceID: string;
  SourceSecretKey: string;
  UserData: UserIdAndSecretInterface;
  Schedules: SwitchAppointmentSchedule[];
}

export class GetHistoryModel {
  petId: ID;
  accountId?: ID;
  specialistId?: ID;
}

export interface HistoryResponse {
  ErrorCode: number;
  History: HistoryCard[];
  Message: string;
  Success: boolean;
  Warnings: any;
}

export interface HistoryCard {
  PetName: string;
  PetTypeId: ID;
  UserName: string;
  UserPhone: string;
  AppointmentDraft: AppointmentDraft;
}
