import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  AppointmentDraft,
  DraftToDetailModel,
  DraftToDetails,
} from '../../../shared/interfaces/appointments/appointments';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { AppointmentService } from '../../../shared/services/appointment.service';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { SelectInterface } from '../../../shared/interfaces/select.interface';

@Component({
  selector: 'app-manipulation-form',
  templateUrl: './manipulation-form.component.html',
  styleUrls: ['./manipulation-form.component.scss'],
})
export class ManipulationFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() appointmentDraft: AppointmentDraft;
  @Input() appointmentAfterSaveDraft: AppointmentDraft;
  @Input() updateTypeCreate: boolean;
  @Input()
  set submit(dateSubmit: Date) {
    if (dateSubmit) {
      this.onPrepare();
    }
  }
  @Output() submitted = new EventEmitter();

  form: FormGroup;
  serviceList: SelectInterface[] = [];
  isLoading = true;

  constructor(
    private fb: FormBuilder,
    private appointmentService: AppointmentService
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      id: [],
      manipulations: this.fb.array([]),
    });

    if (this.appointmentDraft !== undefined) {
      if (!this.updateTypeCreate) {
        this.updateFormAfterGet();
      }
    }

    this.getClinicServices();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.appointmentDraft &&
      this.appointmentDraft !== undefined &&
      this.form
    ) {
      if (!this.updateTypeCreate) {
        this.updateFormAfterGet();
      }
    }

    if (
      changes.appointmentAfterSaveDraft &&
      this.appointmentAfterSaveDraft !== undefined &&
      this.form
    ) {
      this.updateIds();
    }
  }

  getClinicServices() {
    this.appointmentService
      .getClinicServices(this.appointmentDraft.AccountId)
      .pipe<SelectInterface[]>(untilDestroyed(this))
      .subscribe((res) => {
        this.serviceList = res;
        this.isLoading = false;
        const controls = this.form.get('manipulations') as FormArray;
        let i = 0;
        while (i < controls.length) {
          if (controls.at(i).value.serviceId) {
            const id = controls.at(i).value.serviceId.value;
            let name = '';
            const service = this.serviceList.find((d) => d.value === id);
            if (service) {
              name = service.name;
            }
            controls.at(i).patchValue({
              serviceId: {
                value: id,
                name,
              },
            });
          }
          i++;
        }
      });
  }

  updateFormAfterGet() {
    this.appointmentDraft.DraftToDetails.map((d) => {
      const manipulation = new DraftToDetails();
      manipulation.Id = d.Id;
      manipulation.AccClinicServiceId = d.AccClinicServiceId;

      this.addManipulation(manipulation);
    });
  }

  updateIds() {
    const controls = this.form.get('manipulations') as FormArray;

    this.appointmentAfterSaveDraft.DraftToDetails.map((x, i) => {
      controls.at(i).patchValue({ id: x.Id });
    });
  }

  addManipulation(model?: DraftToDetails) {
    const controls = this.form.get('manipulations') as FormArray;
    if (model) {
      let name = '';
      if (this.serviceList && this.serviceList.length > 0) {
        name = this.serviceList.find(
          (x) => x.value === model.AccClinicServiceId
        ).name;
      }
      controls.push(
        this.fb.group({
          id: model.Id,
          serviceId: {
            value: model.AccClinicServiceId,
            name,
          },
        })
      );
    } else {
      controls.push(
        this.fb.group({
          id: [],
          serviceId: [],
        })
      );
    }
  }

  getControls(form, group: string) {
    return form.get(group).controls;
  }

  removeFormGroup(form, group: string, i) {
    const controls = form.get(group) as FormArray;
    controls.removeAt(i);
  }

  onSubmit() {
    const prepare = this.prepareForm();
    this.submitted.emit(prepare);
  }

  onPrepare() {
    this.appointmentService.prepareManipulationsForm = this.prepareForm();
  }

  prepareForm() {
    const controls = this.form.controls;
    const _model: DraftToDetailModel[] = [];
    controls.manipulations.value.map((manipulation) => {
      const newManipulation = new DraftToDetailModel();
      newManipulation.init();
      if (manipulation.id) {
        newManipulation.Id = manipulation.id;
      }
      newManipulation.AccClinicService.Value = manipulation.serviceId.value;

      _model.push(newManipulation);
    });

    return _model;
  }

  ngOnDestroy() {}
}
