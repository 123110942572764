import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { api } from '../../../environments/api';

@Injectable()
export class HttpService {
  constructor(private httpClient: HttpClient) {}

  /*
   * Post Base request
   * */

  postBaseRequest(data, url) {
    const request = this.formatingRequest(data);

    return this.httpClient.post(api.baseDomain + api.baseUrl + url, request, {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
    });
  }

  /*
   * Post Base request text/plain
   * */

  postBaseRequestText<T>(data, url = 'Data') {
    const request = this.formatingRequest(data);

    return this.httpClient.post<T>(
      api.baseDomain + api.baseUrlService + url,
      request,
      {
        headers: new HttpHeaders().set('Content-Type', 'text/plain'),
      }
    );
  }

  /*
   * Post custom
   * */

  postCustom(url, request, headers) {
    return this.httpClient.post(url, request, {
      headers,
    });
  }

  /*
   * Get request
   * */

  getBaseRequest() {}

  /*
   * Get request api 2
   * */

  getRequestApi2<T>(url, params?) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json-patch+json',
      'API-VERSION': '2',
    });

    return this.httpClient.get<T>(`${api.baseDomain}/${url}`, {
      headers,
      params,
    });
  }

  /*
   * Post request api 2
   * */

  postRequestApi2<T>(model, url) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json-patch+json',
      'API-VERSION': '2',
    });

    const request = this.formatingRequest(model);

    return this.httpClient.post<T>(
      `https://api-staging-marko.konnektu.ru/${url}`,
      request,
      {
        headers,
      }
    );
  }

  /*
   * Post Data Suggestion
   * */

  postBaseCustomUrl(request, url) {
    return this.httpClient.post(api.baseDomain + url, JSON.stringify(request), {
      headers: new HttpHeaders().set(
        'Content-Type',
        'application/json;charset=utf-8'
      ),
    });
  }

  /*
   * Wrap requests in Request Object
   * */

  formatingRequest(obj: any) {
    return JSON.stringify({
      Request: obj,
    });
  }
}
