import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { timepickerTheme } from '../../../shared/const/const';
import {
  AppointmentDraft,
  AppointmentDraftModel,
  HealthFeatureModel,
  PetTypesEnum,
} from '../../../shared/interfaces/appointments/appointments';
import {
  petBreedCat,
  petBreedDog,
  petGenderId,
  petIsNeutered,
  petLengthCoat,
  petTypes,
} from '../../../shared/catalogs/pets.catalog';
import { SelectInterface } from '../../../shared/interfaces/select.interface';
import * as moment from 'moment';
import {
  connectDateTime,
  convertToF,
  dateToString,
} from '../../../shared/functions/functions';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { AppointmentService } from '../../../shared/services/appointment.service';

@Component({
  selector: 'app-pet-form',
  templateUrl: './pet-form.component.html',
  styleUrls: ['./pet-form.component.scss'],
})
export class PetFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() appointmentDraft: AppointmentDraft;
  @Input() appointmentAfterSaveDraft: AppointmentDraft;
  @Input() updateTypeCreate: boolean;
  @Input()
  set submit(dateSubmit: Date) {
    if (dateSubmit) {
      this.onPrepare();
    }
  }
  @Output() submitted = new EventEmitter();

  form: FormGroup;
  timePickerTheme = timepickerTheme;
  petTypesCatalog = petTypes;

  selectBreedCat: SelectInterface[] = petBreedCat;
  selectBreedDog: SelectInterface[] = petBreedDog;
  selectGender: SelectInterface[] = petGenderId;
  selectLengthCoat: SelectInterface[] = petLengthCoat;
  selectIsNeutered: SelectInterface[] = petIsNeutered;
  init = true;

  constructor(
    private fb: FormBuilder,
    private appointmentService: AppointmentService
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      startDate: new FormControl('', [Validators.required]),
      startTime: new FormControl('', [Validators.required]),
      name: new FormControl('', [Validators.required]),
      view: new FormControl('', [Validators.required]),
      breed: new FormControl('', [Validators.required]),
      gender: new FormControl('', [Validators.required]),
      birthday: new FormControl('', [Validators.required]),
      lengthCoat: new FormControl(),
      color: new FormControl(),
      neutered: new FormControl(),
      weight: new FormControl(),
      height: new FormControl(),
      temperature: new FormControl(),
      idHeartFailure: new FormControl(),
      dermatosisHairLoss: new FormControl(),
      heartFailure: new FormControl(),
      kidneyDisease: new FormControl(),
      sensitiveDigestion: new FormControl(),
      diseasesJoint: new FormControl(),
      allergy: new FormControl(),
      obesity: new FormControl(),
      liverDisease: new FormControl(),
      urolithiasisDisease: new FormControl(),
      recovery: new FormControl(),
      diabetes: new FormControl(),
    });

    if (this.appointmentDraft !== undefined) {
      if (this.updateTypeCreate) {
        this.updateFormAfterCreate();
      } else {
        this.updateFormAfterGet();
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.appointmentDraft &&
      this.appointmentDraft !== undefined &&
      this.form
    ) {
      this.init = true;
      if (this.updateTypeCreate) {
        this.updateFormAfterCreate();
      } else {
        this.updateFormAfterGet();
      }
    }

    if (
      changes.appointmentAfterSaveDraft &&
      this.appointmentAfterSaveDraft !== undefined &&
      this.form
    ) {
      this.updateIds();
    }
  }

  updateFormAfterCreate() {
    const startTime = moment(this.appointmentDraft.StartDate).format('h:mm');
    const breed: SelectInterface = {
      value: this.appointmentDraft.Pet.BtcBreedId,
      name: this.getCatalogBreed().find(
        (d) => d.value === this.appointmentDraft.Pet.BtcBreedId
      ).name,
    };

    const heights = this.appointmentDraft.Pet.PetGrowth;
    const weights = this.appointmentDraft.Pet.PetWeight;

    this.form.patchValue({
      startDate: this.appointmentDraft.StartDate,
      startTime,
      name: this.appointmentDraft.Pet.BtcName,
      view: this.appointmentDraft.Pet.BtcTypeId,
      breed,
      gender: this.appointmentDraft.Pet.BtcSexId,
      weight: weights[heights.length - 1].BtcValue,
      height: heights[heights.length - 1].BtcValue,
      temperature: this.appointmentDraft.TemperatureC,
      birthday: this.appointmentDraft.Pet.BtcBirthday,
      lengthCoat: this.appointmentDraft.Pet.LengthCoatId,
      color: this.appointmentDraft.Pet.BtcColor,
      neutered: this.appointmentDraft.Pet.IsNeutered,
      dermatosisHairLoss: this.appointmentDraft.Pet.DermatosisHairLoss,
      heartFailure: this.appointmentDraft.Pet.HeartFailure,
      kidneyDisease: this.appointmentDraft.Pet.KidneyDisease,
      sensitiveDigestion: this.appointmentDraft.Pet.SensitiveDigestion,
      diseasesJoint: this.appointmentDraft.Pet.DiseasesJoint,
      allergy: this.appointmentDraft.Pet.Allergy,
      obesity: this.appointmentDraft.Pet.Obesity,
      liverDisease: this.appointmentDraft.Pet.LiverDisease,
      urolithiasisDisease: this.appointmentDraft.Pet.UrolithiasisDisease,
      recovery: this.appointmentDraft.Pet.Recovery,
      diabetes: this.appointmentDraft.Pet.Diabetes,
    });

    this.onSubmit();
  }

  updateFormAfterGet() {
    this.init = true;
    const startTime = moment(this.appointmentDraft.StartDate).format('h:mm');
    const breed: SelectInterface = {
      value: this.appointmentDraft.Pet.BtcBreedId,
      name: this.getCatalogBreed().find(
        (d) => d.value === this.appointmentDraft.Pet.BtcBreedId
      ).name,
    };

    let healthFeature;

    if (
      this.appointmentDraft.HealthFeatures &&
      this.appointmentDraft.HealthFeatures.length > 0
    ) {
      healthFeature = this.appointmentDraft.HealthFeatures[
        this.appointmentDraft.HealthFeatures.length - 1
      ];
    } else {
      healthFeature = {
        DermatosisHairLoss: false,
        HeartFailure: false,
        KidneyDisease: false,
        SensitiveDigestion: false,
        DiseasesJoint: false,
        Allergy: false,
        Obesity: false,
        LiverDisease: false,
        UrolithiasisDisease: false,
        Recovery: false,
        Diabetes: false,
      };
    }

    this.form.patchValue({
      startDate: this.appointmentDraft.StartDate,
      startTime,
      name: this.appointmentDraft.Pet.BtcName,
      view: this.appointmentDraft.Pet.BtcTypeId,
      breed,
      gender: this.appointmentDraft.SexId,
      birthday: this.appointmentDraft.Birthday,
      lengthCoat: this.appointmentDraft.LengthCoatId,
      color: this.appointmentDraft.Color,
      neutered: this.appointmentDraft.IsNeutered,
      weight: this.appointmentDraft.Weight,
      height: this.appointmentDraft.Height,
      temperature: this.appointmentDraft.TemperatureC,
      dermatosisHairLoss: healthFeature.DermatosisHairLoss,
      heartFailure: healthFeature.HeartFailure,
      kidneyDisease: healthFeature.KidneyDisease,
      sensitiveDigestion: healthFeature.SensitiveDigestion,
      diseasesJoint: healthFeature.DiseasesJoint,
      allergy: healthFeature.Allergy,
      obesity: healthFeature.Obesity,
      liverDisease: healthFeature.LiverDisease,
      urolithiasisDisease: healthFeature.UrolithiasisDisease,
      recovery: healthFeature.Recovery,
      diabetes: healthFeature.Diabetes,
    });

    this.onSubmit();
  }

  updateIds() {
    this.appointmentDraft.HealthFeatures[
      this.appointmentDraft.HealthFeatures.length - 1
    ].Id = this.appointmentAfterSaveDraft.HealthFeatures[
      this.appointmentAfterSaveDraft.HealthFeatures.length - 1
    ].Id;
  }

  onSubmit() {
    const prepare = this.prepareForm();
    this.submitted.emit(prepare);
  }

  onPrepare() {
    this.appointmentService.preparePetForm = this.prepareForm();
  }

  prepareForm() {
    const controls = this.form.controls;
    const startDate = connectDateTime(
      controls.startDate.value,
      controls.startTime.value,
      true
    );
    const _model = new AppointmentDraftModel();
    _model.init();
    _model.StartDate.Value = startDate;

    let birthday;

    if (controls.birthday.value) {
      birthday = dateToString(controls.birthday.value);
    } else {
      birthday = null;
    }

    _model.Breed.Value = controls.breed.value.value;
    _model.Sex.Value = controls.gender.value;
    _model.Birthday.Value = birthday;
    _model.LengthCoat.Value = controls.lengthCoat.value;
    _model.Color.Value = controls.color.value;
    _model.IsNeutered.Value = controls.neutered.value;
    _model.Weight.Value = controls.weight.value;
    _model.Height.Value = controls.height.value;
    _model.TemperatureC.Value = parseInt(controls.temperature.value, 10);
    _model.TemperatureF.Value = convertToF(controls.temperature.value);

    _model.HealthFeature = [];
    const newHealthFeature = new HealthFeatureModel();
    newHealthFeature.init();

    if (
      this.appointmentDraft.HealthFeatures &&
      this.appointmentDraft.HealthFeatures.length > 0
    ) {
      newHealthFeature.Id = this.appointmentDraft.HealthFeatures[
        this.appointmentDraft.HealthFeatures.length - 1
      ].Id;
    }
    newHealthFeature.DermatosisHairLoss.Value =
      controls.dermatosisHairLoss.value;
    newHealthFeature.HeartFailure.Value = controls.heartFailure.value;
    newHealthFeature.KidneyDisease.Value = controls.kidneyDisease.value;
    newHealthFeature.SensitiveDigestion.Value =
      controls.sensitiveDigestion.value;
    newHealthFeature.DiseasesJoint.Value = controls.diseasesJoint.value;
    newHealthFeature.Allergy.Value = controls.allergy.value;
    newHealthFeature.Obesity.Value = controls.obesity.value;
    newHealthFeature.LiverDisease.Value = controls.liverDisease.value;
    newHealthFeature.UrolithiasisDisease.Value =
      controls.urolithiasisDisease.value;
    newHealthFeature.Recovery.Value = controls.recovery.value;
    newHealthFeature.Diabetes.Value = controls.diabetes.value;

    _model.HealthFeature.push(newHealthFeature);

    return _model;
  }

  getCatalogBreed(): SelectInterface[] {
    let breeds;
    switch (this.appointmentDraft.Pet.BtcTypeId) {
      case PetTypesEnum.Cat:
        breeds = this.selectBreedCat;
        break;
      case PetTypesEnum.Dog:
        breeds = this.selectBreedDog;
        break;
    }

    return breeds;
  }

  ngOnDestroy() {}
}
