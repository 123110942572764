import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { CardComponent } from './card.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TextMaskModule } from 'angular2-text-mask';
import { SharedModule } from '../shared/shared.module';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatAutocompleteModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatDialogModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatNativeDateModule,
  MatSelectModule,
  MatSnackBarModule,
  MatTableModule,
  MatTabsModule,
} from '@angular/material';
import { ngfModule } from 'angular-file';
import { PipesModule } from '../shared/pipes/pipes.module';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { CatalogNamePipe } from '../shared/pipes/catalog-name.pipe';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MY_FORMATS } from '../account/account.module';
import { CardRoutingModule } from './card-routing.module';
import { CardFormComponent } from './card-form/card-form.component';
import { PetFormComponent } from './card-form/pet-form/pet-form.component';
import { DiagnosisFormComponent } from './card-form/diagnosis-form/diagnosis-form.component';
import { AnamnesisFormComponent } from './card-form/anamnesis-form/anamnesis-form.component';
import { DiagnosticFormComponent } from './card-form/diagnostic-form/diagnostic-form.component';
import { ManipulationFormComponent } from './card-form/manipulation-form/manipulation-form.component';
import { MedicationPrescriptionFormComponent } from './card-form/medication-prescription-form/medication-prescription-form.component';
import { AppointmentMedicationFormComponent } from './card-form/appointment-medication-form/appointment-medication-form.component';
import { AdditionalUseFormComponent } from './card-form/additional-use-form/additional-use-form.component';
import { VaccinationFormComponent } from './card-form/vaccination-form/vaccination-form.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { HistoryComponent } from './card-form/history/history.component';

@NgModule({
  imports: [
    CommonModule,
    CardRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    TextMaskModule,
    SharedModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    ngfModule,
    PipesModule,
    NgxMaterialTimepickerModule,
    MatIconModule,
    MatTabsModule,
    AngularEditorModule,
    MatTableModule,
    MatSnackBarModule,
  ],
  declarations: [
    CardComponent,
    CardFormComponent,
    PetFormComponent,
    DiagnosisFormComponent,
    AnamnesisFormComponent,
    DiagnosticFormComponent,
    ManipulationFormComponent,
    MedicationPrescriptionFormComponent,
    AppointmentMedicationFormComponent,
    AdditionalUseFormComponent,
    VaccinationFormComponent,
    HistoryComponent,
  ],
  providers: [
    DatePipe,
    MatDatepickerModule,
    CatalogNamePipe,
    { provide: LOCALE_ID, useValue: 'ru' },
    { provide: MAT_DATE_LOCALE, useValue: 'ru-RU' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class CardModule {}
