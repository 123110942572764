import { FileInterface } from '../interfaces/file.interface';

export function downscaleImage(dataUrl, newWidth, imageType?, imageArguments?) {
  let image;
  let oldWidth;
  let oldHeight;
  let newHeight;
  let canvas;
  let ctx;
  let newDataUrl;

  // Provide default values
  imageType = imageType || 'image/jpeg';
  imageArguments = imageArguments || 0.8;

  // Create a temporary image so that we can compute the height of the downscaled image.
  image = new Image();
  image.src = dataUrl;
  oldWidth = image.width;
  oldHeight = image.height;
  newHeight = Math.floor((oldHeight / oldWidth) * newWidth);

  // Create a temporary canvas to draw the downscaled image on.
  canvas = document.createElement('canvas');
  canvas.width = newWidth;
  canvas.height = newHeight;

  // Draw the downscaled image on the canvas and return the new data URL.
  ctx = canvas.getContext('2d');
  ctx.drawImage(image, 0, 0, newWidth, newHeight);
  newDataUrl = canvas.toDataURL(imageType, imageArguments);
  return newDataUrl;
}

export function replaceDate(date) {
  return date.replace(/(\d+).(\d+).(\d+)/, '$3/$2/$1');
}

export function dateEncode(date) {
  const timezone = date.match(/\+[0-9]{4}/g)[0];
  const hours = parseInt(timezone.slice(0, 3), 10);
  const minutes = parseInt(timezone.slice(3, 5), 10);
  const offset = hours + ((hours / Math.abs(hours)) * minutes) / 60;

  const newDate = new Date(parseInt(date.match(/[0-9]+/g)[0], 10));

  return new Date(newDate.setHours(newDate.getHours() + offset));
}

export function dateEncodeParseUtc(date) {
  return parseInt(date.match(/[0-9]+/g)[0], 10);
}

export function dateCompilation(date) {
  let dateCompilation: string = new Date(date).getTime().toString();

  if (new Date().toString().match(/([-+][0-9]+)/)) {
    dateCompilation += (new Date().toString().match(/([-+][0-9]+)/) || [''])[0];
  }

  return '/Date(' + dateCompilation + ')/';
}

export function dateEncodeNoUtc(date) {
  return new Date(parseInt(date.match(/[0-9]+/g)[0], 10));
}

export function dateCompilationNoUtc(date) {
  let dateCompilation: string = new Date(date).getTime().toString();

  dateCompilation += '+0000';

  return '/Date(' + dateCompilation + ')/';
}

export function timeCompilation(time) {
  const date = new Date();
  const timeSplit = time.split(':');

  date.setHours(parseInt(timeSplit[0], 10), parseInt(timeSplit[1], 10), 0);

  return dateCompilationNoUtc(date);
}

export function timeCompilationUtc(time) {
  const date = new Date();
  const timeSplit = time.split(':');

  date.setHours(parseInt(timeSplit[0], 10), parseInt(timeSplit[1], 10), 0);

  return dateCompilation(date);
}

export function addZero(n) {
  n = parseInt(n, 10);
  return n < 10 ? '0' + n : n;
}

export function isEmpty(obj) {
  for (const key in obj) {
    return false;
  }
  return true;
}

export function getAvatar(images: FileInterface[]) {
  if (images && images.length) {
    const lastIndex = images.length - 1;

    const name = images[lastIndex].Name;
    const content = images[lastIndex].Content;

    let filename: any = name.split('.');

    filename = filename[filename.length - 1];

    return 'data:image/' + filename + ';base64,' + content;
  } else {
    return null;
  }
}

export function formatPhone(phone) {
  return phone.replace(/[\s()-]+/gi, '');
}

export function getUnixDate(
  milliseconds: boolean = false,
  date: Date = new Date()
) {
  if (milliseconds) {
    return date.getTime();
  } else {
    return (date.getTime() / 1000).toFixed(0);
  }
}

export function connectDateTime(date, time, space = false) {
  let startDate = date;
  let startTime = time;

  if (typeof startDate !== 'string') {
    startDate = startDate.format();
  }

  if (typeof startTime !== 'string') {
    startTime = startTime.format();
  }

  const sep = space ? ' ' : 'T';

  return startDate.split('T')[0] + sep + startTime;
}

export function dateToString(date) {
  if (typeof date !== 'string') {
    date = date.format();
  }
  return date;
}

export function convertToF(celsius) {
  return !celsius ? 0 : (celsius * 9) / 5 + 32;
}

export function convertToC(fahrenheit) {
  return ((fahrenheit - 32) * 5) / 9;
}

export function copyArrayOfObject<T>(array): T {
  return JSON.parse(JSON.stringify(array));
}
