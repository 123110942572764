import { Component, OnDestroy, OnInit } from '@angular/core';
import { CurrentUserResponseInterface } from '../shared/interfaces/user/current-user-response.interface';
import { api } from '../../environments/api';
import { UserService } from '../shared/services/user.service';
import { FileInterface } from '../shared/interfaces/file.interface';
import { UserInterface } from '../shared/interfaces/user/user.interface';
import { AuthService } from '../shared/services/auth.service';
import { DialogService } from '../shared/services/dialog.service';
import { timeout } from 'rxjs/operators';
import { untilDestroyed } from 'ngx-take-until-destroy';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent implements OnInit, OnDestroy {
  avatarFiles: FileInterface[];
  avatar: string;
  avatarId: string;
  showAvatarCap = true;
  preloaderVisibility = true;
  userData: UserInterface;

  constructor(
    public userService: UserService,
    private authService: AuthService,
    private dialogService: DialogService
  ) {}

  ngOnInit() {
    if (!this.userService.getUserData()) {
      this.userService
        .getCurrentUserData()
        .pipe(untilDestroyed(this), timeout(api.requestTimeout))
        .subscribe((response: CurrentUserResponseInterface) => {
          console.log('CurrentUser', response);
          if (response.CurrentUserResult.Success) {
            this.preloaderVisibility = false;
            this.userService.setUserData(response.CurrentUserResult);
            this.userData = response.CurrentUserResult.UserData;
            this.getUserAvatar();

            if (this.userData.TypeId !== api.typeUser) {
              this.authService.logOut();
            }
          } else {
            this.dialogService.showStandardError();
          }
        });
    } else {
      this.preloaderVisibility = false;
      this.getUserAvatar();
      this.userData = this.userService.getUserData().UserData;
    }
  }

  getUserAvatar() {
    this.avatarFiles = this.userService.getUserData().Files;

    if (this.avatarFiles.length) {
      const last = this.avatarFiles.length - 1;

      const name = this.avatarFiles[last].Name;
      const content = this.avatarFiles[last].Content;
      this.avatarId = this.avatarFiles[last].Id;

      let filename: any = name.split('.');

      filename = filename[filename.length - 1];

      this.avatar = 'data:image/' + filename + ';base64,' + content;

      this.userService.userAvatar = this.avatar;

      this.showAvatarCap = false;
    } else {
      this.showAvatarCap = true;
    }
  }

  ngOnDestroy() {}
}
