import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  ActivityMedicineModel,
  ActivityMedicines,
  ActivityToMedicineModel,
  AppointmentDraft,
} from '../../../shared/interfaces/appointments/appointments';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { SelectInterface } from '../../../shared/interfaces/select.interface';
import { frequency } from '../../../shared/catalogs/appointment.catalog';
import { AppointmentService } from '../../../shared/services/appointment.service';

@Component({
  selector: 'app-medication-prescription-form',
  templateUrl: './medication-prescription-form.component.html',
  styleUrls: ['./medication-prescription-form.component.scss'],
})
export class MedicationPrescriptionFormComponent
  implements OnInit, OnChanges, OnDestroy {
  @Input() appointmentDraft: AppointmentDraft;
  @Input() appointmentAfterSaveDraft: AppointmentDraft;
  @Input() updateTypeCreate: boolean;
  @Input()
  set submit(dateSubmit: Date) {
    if (dateSubmit) {
      this.onPrepare();
    }
  }
  @Output() submitted = new EventEmitter();

  form: FormGroup;
  serviceList = [];
  frequencySelect: SelectInterface[] = frequency;
  init = true;

  constructor(
    private fb: FormBuilder,
    private appointmentService: AppointmentService
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      medicationPrescriptions: this.fb.array([]),
    });

    if (this.appointmentDraft !== undefined) {
      if (!this.updateTypeCreate) {
        this.updateFormAfterGet();
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.appointmentDraft &&
      this.appointmentDraft !== undefined &&
      this.form
    ) {
      if (!this.updateTypeCreate) {
        this.updateFormAfterGet();
      }
    }

    if (
      changes.appointmentAfterSaveDraft &&
      this.appointmentAfterSaveDraft !== undefined &&
      this.form
    ) {
      this.updateIds();
    }
  }

  updateFormAfterGet() {
    if (this.appointmentDraft.ActivityMedicines) {
      this.appointmentDraft.ActivityMedicines.map((d) => {
        const medicine = new ActivityMedicines();
        medicine.Id = d.Id;
        medicine.Name = d.Name;
        medicine.Count = d.Count;
        medicine.HowMany = d.HowMany;
        medicine.FrequencyId = d.FrequencyId;
        medicine.Description = d.Description;

        this.addMedication(medicine);
      });
    }
  }

  updateIds() {
    const controls = this.form.get('medicationPrescriptions') as FormArray;

    if (this.appointmentAfterSaveDraft.ActivityMedicines) {
      this.appointmentAfterSaveDraft.ActivityMedicines.map((x, i) => {
        controls.at(i).patchValue({ id: x.Id });
      });
    }
  }

  addMedication(model?: ActivityMedicines) {
    const controls = this.form.get('medicationPrescriptions') as FormArray;
    if (model) {
      controls.push(
        this.fb.group({
          id: model.Id,
          name: model.Name,
          count: model.Count,
          howMany: model.HowMany,
          frequency: model.FrequencyId,
          description: model.Description,
        })
      );
    } else {
      controls.push(
        this.fb.group({
          id: [],
          name: [],
          count: [],
          howMany: [],
          frequency: [],
          description: [],
        })
      );
    }
  }

  getControls(form, group: string) {
    return form.get(group).controls;
  }

  removeFormGroup(form, group: string, i) {
    const controls = form.get(group) as FormArray;
    controls.removeAt(i);
  }

  onSubmit() {
    const prepare = this.prepareForm();
    this.submitted.emit(prepare);
  }

  onPrepare() {
    this.appointmentService.prepareActivityMedicineForm = this.prepareForm();
  }

  prepareForm() {
    const controls = this.form.controls;
    const _model: ActivityMedicineModel[] = [];
    controls.medicationPrescriptions.value.map((medication) => {
      const newMedication = new ActivityMedicineModel();
      newMedication.init();
      if (medication.id) {
        newMedication.Id = medication.id;
      }
      newMedication.Name.Value = medication.name;
      newMedication.Count.Value = medication.count;
      newMedication.HowMany.Value = medication.howMany;
      newMedication.Frequency.Value = medication.frequency;
      newMedication.Description.Value = medication.description;

      _model.push(newMedication);
    });

    return _model;
  }

  ngOnDestroy() {}
}
