import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../shared/services/auth.guard';
import { CardComponent } from './card.component';
import { CardFormComponent } from './card-form/card-form.component';

const routes: Routes = [
  {
    path: 'card',
    component: CardComponent,
    canActivate: [AuthGuard],
    children: [{ path: ':id', component: CardFormComponent }],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CardRoutingModule {}
