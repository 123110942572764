import { Component, Inject, OnInit } from '@angular/core';
import { Appointment } from '../../../shared/interfaces/doctor/get-appointment';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-appointment-info-dialog',
  templateUrl: './appointment-info-dialog.component.html',
  styleUrls: ['./appointment-info-dialog.component.scss'],
})
export class AppointmentInfoDialogComponent implements OnInit {
  appointment: Appointment;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<AppointmentInfoDialogComponent>
  ) {}

  ngOnInit() {
    this.appointment = this.dialogData.appointment;
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
