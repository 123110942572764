import { Injectable } from '@angular/core';

import { api } from '../../../environments/api';

import { HttpService } from './http.service';
import { BaseService } from './base.service';
import { DoctorFullInterface } from '../interfaces/doctor/doctor-full.interface';
import { UserService } from './user.service';
import {
  GetAppointment,
  ScheduleDates,
} from '../interfaces/doctor/get-appointment';

@Injectable()
export class DoctorService {
  constructor(
    private baseService: BaseService,
    private userService: UserService,
    private httpService: HttpService
  ) {}

  /*
   * Edit current Doctor Data
   * */

  editDoctor(request: DoctorFullInterface) {
    const userIdAndSecret = this.userService.getUserIdAndSecret();

    request.UserData.UserId = userIdAndSecret.UserId;
    request.UserData.SecretKey = userIdAndSecret.SecretKey;

    request.UserData = this.baseService.clearEmpty(request.UserData);

    return this.httpService.postBaseRequest(request, api.paths.user.editUser);
  }

  getAppointment(data: ScheduleDates) {
    const userIdAndSecret = this.userService.getUserIdAndSecret();

    const request: GetAppointment = {
      SourceID: api.sourceID,
      SourceSecretKey: api.sourceSecretKey,
      UserData: {
        UserId: userIdAndSecret.UserId,
        SecretKey: userIdAndSecret.SecretKey,
      },
      Specialist: {
        SpecialistId: userIdAndSecret.UserId,
      },
      Schedule: {
        StartDate: data.StartDate,
        DueDate: data.DueDate,
      },
    };

    return this.httpService.postBaseRequest(
      request,
      api.paths.doctor.getAppointment
    );
  }
}
