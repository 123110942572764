import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  AppointmentDraft,
  DiagnosticFileModel,
  DiagnosticModel,
} from '../../../shared/interfaces/appointments/appointments';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { editorConfig } from '../editor-config';
import { SelectInterface } from '../../../shared/interfaces/select.interface';
import { petBreedCat } from '../../../shared/catalogs/pets.catalog';
import { AppointmentService } from '../../../shared/services/appointment.service';

@Component({
  selector: 'app-diagnostic-form',
  templateUrl: './diagnostic-form.component.html',
  styleUrls: ['./diagnostic-form.component.scss'],
})
export class DiagnosticFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() appointmentDraft: AppointmentDraft;
  @Input() appointmentAfterSaveDraft: AppointmentDraft;
  @Input() updateTypeCreate: boolean;
  @Input()
  set submit(dateSubmit: Date) {
    if (dateSubmit) {
      this.onPrepare();
    }
  }
  @Output() submitted = new EventEmitter();
  form: FormGroup;
  formControlBlueprint: FormControl;
  editorConfig = editorConfig;

  selectBreed: SelectInterface[] = petBreedCat;
  init = true;

  constructor(
    private fb: FormBuilder,
    private appointmentService: AppointmentService
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      id: [],
      text: [],
      files: this.fb.array([]),
    });

    this.formControlBlueprint = this.fb.control([]);

    if (this.appointmentDraft !== undefined) {
      if (!this.updateTypeCreate) {
        this.updateFormAfterGet();
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.appointmentDraft &&
      this.appointmentDraft !== undefined &&
      this.form
    ) {
      if (!this.updateTypeCreate) {
        this.updateFormAfterGet();
      }
    }

    if (
      changes.appointmentAfterSaveDraft &&
      this.appointmentAfterSaveDraft !== undefined &&
      this.form
    ) {
      this.updateIds();
    }
  }

  updateFormAfterGet() {
    const diagnostic = this.appointmentDraft.Diagnostics[0];

    if (diagnostic) {
      this.form.patchValue({
        id: diagnostic.Id,
        text: diagnostic.HTMLCode,
      });

      diagnostic.DiagnosticFiles.map((d) => {
        const fileType = d.Name.split('.');
        const newFile = {
          id: d.Id,
          file: d.Base64,
          name: d.Name,
          type: fileType[fileType.length - 1],
          date: d.CreatedOn,
          link: 'data:image/jpeg;base64,' + d.Base64,
        };

        this.addFilesFromArray(this.form, 'files', newFile);
      });
    }
  }

  updateIds() {
    const diagnostic = this.appointmentAfterSaveDraft.Diagnostics[0];
    if (diagnostic) {
      this.form.patchValue({ id: diagnostic.Id });
      const controls = this.form.get('files') as FormArray;

      diagnostic.DiagnosticFiles.map((x, i) => {
        controls.at(i).patchValue({ id: x.Id });
      });
    }
  }

  addFilesFromArray(form, group: string, file) {
    const controls = form.get(group) as FormArray;
    controls.push(
      this.fb.group({
        id: [file.id],
        file: [file.file],
        name: [file.name],
        type: [file.type],
        date: [file.date],
        link: [file.link],
      })
    );
    this.onSubmit();
  }

  addFile(event) {
    const file = (event.target as HTMLInputElement).files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const newFile = {
        id: '',
        file: reader.result,
        name: file.name,
        type: file.type,
        date: new Date(),
        link: reader.result,
      };
      this.addFilesFromArray(this.form, 'files', newFile);
    };
  }

  getControls(form, group: string) {
    return form.get(group).controls;
  }

  removeFormGroup(form, group: string, i) {
    const controls = form.get(group) as FormArray;
    controls.removeAt(i);
  }

  onSubmit() {
    const prepare = this.prepareForm();
    this.submitted.emit(prepare);
  }

  onPrepare() {
    this.appointmentService.prepareDiagnosticForm = this.prepareForm();
  }

  prepareForm() {
    const controls = this.form.controls;
    const _model: DiagnosticModel[] = [];
    const newDiagnostic = new DiagnosticModel();
    newDiagnostic.init();
    if (controls.id.value) {
      newDiagnostic.Id = controls.id.value;
    }
    newDiagnostic.HTMLCode.Value = controls.text.value;
    newDiagnostic.DiagnosticFile = [];

    controls.files.value.map((file) => {
      const newFile = new DiagnosticFileModel();
      newFile.init();
      if (file.id) {
        newFile.Id = file.id;
      }
      newFile.CreatedOn.Value = '2021-06-06 05:05';
      newFile.Name.Value = file.name;
      newFile.Base64.Value = file.file.split(',')[1];

      newDiagnostic.DiagnosticFile.push(newFile);
    });

    _model.push(newDiagnostic);

    return _model;
  }

  ngOnDestroy() {}
}
