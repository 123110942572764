import { UserIdAndSecretInterface } from '../user/user-id-and-secret.interface';
import { ID } from './appointments';

export class GetClinicData {
  SourceID: string;
  SourceSecretKey: string;
  UserData: UserIdAndSecretInterface;
  Code: string;
  Parameters: {
    AccountId: ID;
  };
}

export interface ClinicServices {
  ActionId: ID;
  ActivityTypeId: ID;
  BtcClinicServiceId: ID;
  BtcCost: number;
  CustomText: string;
  Id: ID;
}

export interface ClinicMedications {
  MedicineTypeId: ID;
  UnitOfMeasurementId: ID;
  Price: number;
  Name: string;
  Id: ID;
}
