import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AgreementComponent } from './agreement/agreement.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { ClinicAccessComponent } from './clinic-access/clinic-access.component';
import { AuthGuard } from './shared/services/auth.guard';
import { ClinicDenieComponent } from './clinic-denie/clinic-denie.component';

const appRoutes: Routes = [
  { path: '', redirectTo: 'auth/sign-in', pathMatch: 'full' },
  { path: 'agreement', component: AgreementComponent },
  {
    path: 'clinic-access',
    component: ClinicAccessComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'clinic-denie',
    component: ClinicDenieComponent,
    canActivate: [AuthGuard],
  },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
