import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  ActivityToMedicineModel,
  ActivityToMedicines,
  AppointmentDraft,
} from '../../../shared/interfaces/appointments/appointments';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { AppointmentService } from '../../../shared/services/appointment.service';
import { SelectInterface } from '../../../shared/interfaces/select.interface';

@Component({
  selector: 'app-appointment-medication-form',
  templateUrl: './appointment-medication-form.component.html',
  styleUrls: ['./appointment-medication-form.component.scss'],
})
export class AppointmentMedicationFormComponent
  implements OnInit, OnChanges, OnDestroy {
  @Input() appointmentDraft: AppointmentDraft;
  @Input() appointmentAfterSaveDraft: AppointmentDraft;
  @Input() updateTypeCreate: boolean;
  @Input()
  set submit(dateSubmit: Date) {
    if (dateSubmit) {
      this.onPrepare();
    }
  }
  @Output() submitted = new EventEmitter();

  form: FormGroup;
  medicationList: SelectInterface[] = [];
  isLoading = true;

  constructor(
    private fb: FormBuilder,
    private appointmentService: AppointmentService
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      medications: this.fb.array([]),
    });

    if (this.appointmentDraft !== undefined) {
      if (!this.updateTypeCreate) {
        this.updateFormAfterGet();
      }
    }

    this.getClinicMedications();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.appointmentDraft &&
      this.appointmentDraft !== undefined &&
      this.form
    ) {
      if (!this.updateTypeCreate) {
        this.updateFormAfterGet();
      }
    }

    if (
      changes.appointmentAfterSaveDraft &&
      this.appointmentAfterSaveDraft !== undefined &&
      this.form
    ) {
      this.updateIds();
    }
  }

  getClinicMedications() {
    this.appointmentService
      .getClinicMedicines(this.appointmentDraft.AccountId)
      .pipe<SelectInterface[]>(untilDestroyed(this))
      .subscribe((res) => {
        this.medicationList = res;
        this.isLoading = false;
        const controls = this.form.get('medications') as FormArray;
        let i = 0;
        while (i < controls.length) {
          if (controls.at(i).value.name) {
            const id = controls.at(i).value.name.value;
            let name = '';
            const service = this.medicationList.find((d) => d.value === id);
            if (service) {
              name = service.name;
            }
            controls.at(i).patchValue({
              name: {
                value: id,
                name,
              },
            });
          }
          i++;
        }
      });
  }

  updateFormAfterGet() {
    this.appointmentDraft.ActivityToMedicines.map((d) => {
      const medicine = new ActivityToMedicines();
      medicine.Id = d.Id;
      medicine.ClinicMedicineId = d.ClinicMedicineId;
      medicine.Count = d.Count;

      this.addMedication(medicine);
    });
  }

  updateIds() {
    const controls = this.form.get('medications') as FormArray;

    this.appointmentAfterSaveDraft.ActivityToMedicines.map((x, i) => {
      controls.at(i).patchValue({ id: x.Id });
    });
  }

  addMedication(model?: ActivityToMedicines) {
    const controls = this.form.get('medications') as FormArray;
    if (model) {
      controls.push(
        this.fb.group({
          id: model.Id,
          name: { value: model.ClinicMedicineId, name: '' },
          count: model.Count,
        })
      );
    } else {
      controls.push(
        this.fb.group({
          id: [],
          name: [],
          count: [],
        })
      );
    }
  }

  getControls(form, group: string) {
    return form.get(group).controls;
  }

  removeFormGroup(form, group: string, i) {
    const controls = form.get(group) as FormArray;
    controls.removeAt(i);
  }

  onSubmit() {
    const prepare = this.prepareForm();
    this.submitted.emit(prepare);
  }

  onPrepare() {
    this.appointmentService.prepareActivityToMedicineForm = this.prepareForm();
  }

  prepareForm() {
    const controls = this.form.controls;
    const _model: ActivityToMedicineModel[] = [];
    controls.medications.value.map((medication) => {
      const newMedication = new ActivityToMedicineModel();
      newMedication.init();
      if (medication.id) {
        newMedication.Id = medication.id;
      }
      newMedication.ClinicMedicine.Value = medication.name.value;
      newMedication.Count.Value = medication.count;

      _model.push(newMedication);
    });

    return _model;
  }

  ngOnDestroy() {}
}
