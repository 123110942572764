import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-card-sidebar',
  templateUrl: './card-sidebar.component.html',
  styleUrls: ['./card-sidebar.component.scss'],
})
export class CardSidebarComponent implements OnInit {
  constructor(private userService: UserService) {}
  @Output() saveDraft = new EventEmitter();
  @Output() saveAndClose = new EventEmitter();

  ngOnInit() {}

  closeMobileSidebar() {
    this.userService.showMobileSidebar = false;
    this.userService.showMobileOverlay = false;
  }

  onSaveDraft() {
    this.closeMobileSidebarCheck();
    this.saveDraft.emit();
  }

  onSaveAndClose() {
    this.closeMobileSidebarCheck();
    this.saveAndClose.emit();
  }

  closeMobileSidebarCheck() {
    this.userService.showMobileSidebar = this.userService.showMobileSidebar
      ? false
      : null;
    this.userService.showMobileOverlay = this.userService.showMobileOverlay
      ? false
      : null;
  }
}
